import {OverlayTrigger, ProgressBar, Popover, Table} from "react-bootstrap";
import {useContext, useMemo} from "react";
import {DataContext} from "../../../contexts/DataProviderContext";

export default props => {
    const Data = useContext(DataContext);

    const token = useMemo(() => {
        const response = {
            hardMax: 0,
            totalConsumption: 0,
            totalContextConsumption: 0,
            totalConversationConsumption: 0,
            progresses: {
                initialContext: 0,
                pastPrompts: 0,
            },
            modelFullness: 0,
        };

        if (Data.currentConversation?.tokenUsage?.modelDetails) {
            const usage = Data.currentConversation.tokenUsage;

            response.hardMax = usage.modelDetails.maxTokenLimitForModel;

            let conversationTokenCount = 0;
            let contextInstructionTokenCount = 0;

            Data.currentConversation.chatHistory.forEach(chat => {
                const count = chat?.metadata?.tokenCount ?? 0;

                conversationTokenCount += count;
                if (chat?.metadata?.context || chat?.metadata?.instructions) {
                    contextInstructionTokenCount += count;
                }
            });

            response.totalConsumption = conversationTokenCount;
            response.totalContextConsumption = contextInstructionTokenCount;
            response.totalConversationConsumption = (conversationTokenCount - contextInstructionTokenCount);

            response.progresses.initialContext = Math.ceil((contextInstructionTokenCount/response.hardMax) * 100);
            response.progresses.pastPrompts = Math.ceil((response.totalConversationConsumption/response.hardMax) * 100);

            response.modelFullness = response.progresses.initialContext + response.progresses.pastPrompts;
        }


        return response;
    }, [Data.currentConversation.chatHistory, Data.currentConversation.tokenUsage, Data.settings]);

    if (Data.currentConversation.chatHistory.length <= 0) {
        return null;
    }

    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement={'top'}
            overlay={
                (Data.currentConversation.chatHistory.length > 0 && Data.currentConversation.tokenUsage)
                    ? <Popover id="token-usage-details-popover">
                        <Popover.Header as="h3">Conversation Capacity</Popover.Header>
                        <Popover.Body>
                            <Table borderless size="sm">
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>Model capacity:</td>
                                        <td>{token.hardMax}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Capacity consumed:</td>
                                        <td>{token.totalConsumption} ({token.modelFullness}%)</td>
                                    </tr>
                                    <tr>
                                        <td/>
                                        <td>Context:</td>
                                        <td>{token.totalContextConsumption} ({token.progresses.initialContext}%)</td>
                                    </tr>
                                    <tr>
                                        <td/>
                                        <td>Prompts & responses:</td>
                                        <td>{token.totalConversationConsumption} ({token.progresses.pastPrompts}%)</td>
                                    </tr>
                                    <tr style={{borderTop: '1px solid lightgray'}}>
                                        <td colSpan={2}>Capacity available:</td>
                                        <td>{token.hardMax - token.totalConsumption}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Popover.Body>
                    </Popover>
                    : null
            }
        >
            <ProgressBar className="progress-container" visuallyHidden>
                <span className="progress-text">Conversation Capacity: {Math.round(token.modelFullness)}%</span>
                <ProgressBar
                    key={1}
                    striped
                    now={token.progresses.initialContext}
                    style={{backgroundColor: '#b5d4ea',  color: '#333333'}}
                />
                <ProgressBar
                    key={2}
                    striped
                    now={token.progresses.pastPrompts}
                    style={{backgroundColor: 'rgb(209 211 147)'}}
                />
            </ProgressBar>
        </OverlayTrigger>
    );
};