import {Fragment, useContext, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, MinusIcon } from '@heroicons/react/24/outline'
import GlobalEvents from "../../../events/GlobalEvents";
import {DELETE, GET} from "../../../services/REST";
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en);

export default () => {
    const V2Context = useContext(CompletionV2Context);

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState('');
    const timeAgo = new TimeAgo('en-US');

    const [files, setFiles] = useState([]);

    useEffect(() => {
        GlobalEvents.interpret('SHOW_FILES', () => setOpen(true));
    }, []);

    useEffect(() => {
        if (!open) {
            setStatus('');
            setFiles([]);
        } else {
            setStatus('retrieving');
            GET({
                url: 'chat-from-files/v2/conversation/'+V2Context.conversationId+'/files'
            }).then(res => {
                if (res.status) {
                    setStatus('success');
                    setFiles(res.content.files);
                }
            }).catch(() => {
                setStatus('error');
            });
        }
    }, [open]);

    const deleteFile = fileName => {
        DELETE({
            url: 'chat-from-files/v2/conversation/'+V2Context.conversationId+'/files',
            content: {
                file_name: fileName
            }
        }).then(res => {
            if (res.status) {
                setFiles(iFiles => iFiles.filter(f => f.name !== fileName))
            }
        });
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col bg-white shadow-xl">
                                        <div className="bg-cyan-700 px-4 py-6 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-black font-semibold leading-6 text-white">
                                                    Files
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md text-indigo-200 hover:text-white focus:outline-none"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            {'error' === status && (
                                                <div className="mt-1">
                                                    <p className="text-sm text-gray-100">
                                                        Error retrieving files
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <div className="px-4 py-6 bg-white overflow-y-auto">
                                            <div className="max-w-3xl mx-auto">
                                                <div className="space-y-4">
                                                    {files.map((doc, i) => (
                                                        <details key={i} className="group">
                                                            <summary className="flex items-center justify-between p-4 rounded-lg bg-gray-50 cursor-pointer">
                                                                <a
                                                                    href={process.env.REACT_APP_BACKEND_DATASOURCE_URL.replace('/api', '')+'/'+doc.path}
                                                                    className="text-sm font-medium text-gray-900"
                                                                >
                                                                    {doc.name}
                                                                </a>
                                                                <pre>{timeAgo.format(new Date(doc.created_at))}</pre>
                                                                <MinusIcon
                                                                    className="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180"
                                                                    onClick={() => deleteFile(doc.name)}
                                                                />
                                                            </summary>
                                                        </details>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
