
export default props => {
    return (
        <button
            className="flex items-center justify-center bg-cyan-700 hover:bg-cyan-800 rounded-xl text-white px-4 py-1 flex-shrink-0"
            onClick={() => props.onClick && props.onClick()}
        >
            <span>Send</span>
            <span className="ml-2">
                <svg
                    className="w-4 h-4 transform rotate-90 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    />
                </svg>
            </span>
        </button>
    );
};