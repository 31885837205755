import {Fragment, useState, useEffect, useContext, useMemo, useRef} from 'react'
import {Combobox, Dialog, Transition} from '@headlessui/react'
import {QuestionMarkCircleIcon, ViewColumnsIcon} from '@heroicons/react/24/outline'
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import {useNavigate} from "react-router-dom";
import OutsideClickHandler from 'react-outside-click-handler';
import {DataContext} from "../../../contexts/DataProviderContext";
import GlobalEvents from "../../../events/GlobalEvents";
import {PersonasContext} from "../../../contexts/PersonasContext";

const PredefinedPrompts = [
    {id: 1, name: 'What is this context about?',},
    {id: 2, name: 'Write a haiku about this context.',},
    {id: 3, name: 'Generate a markdown table of key terms based on the given context that has three columns: Term, Definition, and Page Number where the term was first established.',},
    {id: 4, name: 'What is the royalty structure and are there any royalty notes?',},
    {id: 5, name: 'What were the dates of creation and amendment for the original agreement and subsequent addenda?',},
    {id: 6, name: 'How is the phrase about “bought or sold” worded exactly?',},
];

const quickActions = [];

const classNames = (...classes) => classes.filter(Boolean).join(' ');

export default function ContextPicker() {
    const V2Context = useContext(CompletionV2Context);
    const AsstConfig = useContext(PersonasContext);
    const Data = useContext(DataContext);

    const navigate = useNavigate();
    const [focus, setFocus] = useState(false);
    const [query, setQuery] = useState('');
    const personaPrompt = useMemo(() => {
        if (V2Context.currentPersona) {
            return V2Context.currentPersona.prompt;
        }
        // if (V2Context.currentTeamPersona) {
        //     return V2Context.currentTeamPersona.prompt;
        // }
        return '';
    }, []);
    const [draftPrompt, setDraftPrompt] = useState(personaPrompt);

    const inputRef = useRef();

    const recent = useMemo(() => {
        const rPrompts = JSON.parse(localStorage.getItem('prompts')) || [];
        rPrompts.reverse();
        return rPrompts;
    }, []);

    const filteredProjects = useMemo(() => {
        return {
            recent: recent.filter(project => project.name.toLowerCase().includes(query.toLowerCase()) && project.name !== draftPrompt).slice(0,5), // Gets top 5 matches
            predefinedPrompts: PredefinedPrompts.filter(project => project.name.toLowerCase().includes(query.toLowerCase()) && project.name !== draftPrompt).slice(0,5) // Gets top 5 matches
        };
    }, [query, recent]);

    useEffect(() => {
        if (document.activeElement) {
            document.activeElement.blur();
        }
        if (inputRef.current) {
            focus ? inputRef.current.focus() : inputRef.current.blur();
        }
    }, [focus]);

    const updateHeight = element => {
        if (element.value && element.value.split('\n').length > 1) {
            element.rows = element.value.split('\n').length;
        } else {
            let estRows = parseInt(element.value.length / 70) + 1;
            element.rows = estRows > 5 ? 5 : estRows;
        }
    }

    useEffect(() => {
        inputRef.current && updateHeight(inputRef.current);
    }, [query]);

    useEffect(() => {
        if (inputRef.current) {
            ['input', 'change'].forEach(evtName => {
                inputRef.current.addEventListener(evtName, e => updateHeight(e.target));
            });
        }
    }, [inputRef.current]);

    useEffect(() => {
        setTimeout(() => {
            V2Context.currentFile?.files?.length > 0 && V2Context.currentModel ?
                (V2Context.currentFile.files[0].extension === 'pdf' && !V2Context.currentFile.files[0].subsection ? setFocus(false) : setFocus(true)) : setFocus(false);
        }, 20);
    }, [V2Context.currentFile, V2Context.currentModel]);

    const setPrompt = (event, final) => {
        let prompt;
        if (event?.target?.value) {
            prompt = event.target.value;
        } else {
            prompt = Object.values(filteredProjects).flat().find(p => p.id === event)?.name;
        }
        if (final) {
            V2Context.setCurrentPrompt(prompt);
            savePrompt();
            setQuery('');
            setDraftPrompt(personaPrompt);
        } else {
            setDraftPrompt(prompt);
            setQuery(prompt);
            setFocus(false);
            setTimeout(() => setFocus(true), 100);
        }
    };

    const savePrompt = () => {
        const rPrompts = JSON.parse(localStorage.getItem('prompts')) || [];
        if (query.trim().split(' ').length > 2 && rPrompts.findIndex(p => p.name === query) < 0) {
            rPrompts.push({id: Date.now(), name: query});
            localStorage.setItem('prompts', JSON.stringify(rPrompts.slice(-20))); // Saves recent 20 prompts
        }
    };

    const submit = () => {
        if (!V2Context.currentPrompt && draftPrompt) {
            V2Context.setCurrentPrompt(draftPrompt);
            savePrompt();
        }

        const callback = (status, convId) => {
            if (status) {
                navigate('/conversation/'+convId);
                V2Context.setConversationLoaded(false);
            }
        };

        if ((
                ((['default', 'default-async'].indexOf(V2Context.currentPersona?.id) < 0) && V2Context.currentPersona?.contextRequired) ||
                (V2Context.currentMultiPersonas.filter(p1 => AsstConfig.personas.find(p2 => p2.id === p1).contextRequired).length > 0)
            ) &&
            (!V2Context.currentFile || !V2Context.currentFile?.files || V2Context.currentFile?.files?.length == 0)
        ) {
            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Please choose a context file!'});
            return;
        }
        if (V2Context.currentFile?.files?.length > 0 || 'team' === V2Context.currentPersona?.type) {
            Data.confirmVector(V2Context.currentFile, callback);
        } else {
            Data.confirmSimpleVectorConversation(callback);
        }
    }

    const filterAndSaveDraftPrompt = (value) => {
        setDraftPrompt(value);
        setQuery(value);
    }

    return (
        <Transition.Root
            id="prompt-v2-headless-combobox"
            show={true}
            as={Fragment}
            appear
        >
            <Dialog
                id="prompt-v2-headless-combobox-div"
                as="div"
                className="relative z-10 m-auto"
                onClose={() => null}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className=" inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
                </Transition.Child>

                <div className=" w-screen z-10 p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className="mx-auto max-w-45 transform rounded-xl bg-white p-2 shadow-md ring-1 ring-black ring-opacity-10 transition-all"
                        >
                            <OutsideClickHandler
                                onOutsideClick={() => setFocus(false)}
                            >
                                <Combobox
                                    onChange={event => {
                                        setPrompt(event, false);
                                    }}
                                >
                                    <div className="relative">
                                        <QuestionMarkCircleIcon
                                            className="pointer-events-none absolute left-3.5 top-3 h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <textarea
                                            ref={inputRef}
                                            id="chat"
                                            rows={1}
                                            className="w-full border-0 bg-transparent pl-11 pr-11 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm overflow-auto prompt-palette-textarea"
                                            value={draftPrompt}
                                            onChange={event => filterAndSaveDraftPrompt(event.target.value)}
                                            onKeyDown={e => {
                                                if (!e.shiftKey && e.code === 'Enter') {
                                                    setPrompt(e, true);
                                                    submit();
                                                }
                                            }}
                                            autoComplete={'off'}
                                            placeholder="Enter your prompt here ..."
                                            onClick={() => setFocus(true)}
                                            onFocus={() => setFocus(true)}
                                        />
                                        <button
                                            className="flex items-center justify-center hover:text-black px-1 py-1 flex-shrink-0 absolute right-3.5 top-3 h-6 w-6 text-gray-400"
                                            onClick={() => submit()}
                                        >
                                            <span className="ml-2">
                                                <svg
                                                    className="w-5 h-5 transform rotate-90 -mt-px"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>

                                    {focus && <Combobox.Options
                                        static
                                        className="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
                                    >
                                        {filteredProjects.recent.length > 0 && (
                                            <li className="p-2">
                                                <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
                                                    Recently used
                                                </h2>
                                                <ul className="text-sm text-gray-700">
                                                    {filteredProjects.recent.map(project => (
                                                        <Combobox.Option
                                                            key={project.name}
                                                            value={project.id}
                                                            className={({active}) =>
                                                                classNames(
                                                                    'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                                                    active && 'bg-cyan-900/80 text-white'
                                                                )
                                                            }
                                                        >
                                                            {({active}) => (
                                                                <>
                                                                    <span
                                                                        className="ml-3 flex-auto">{project.name}</span>
                                                                    {active && <span
                                                                        className="ml-3 flex-none text-cyan-200">Select ...</span>}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))}
                                                </ul>
                                            </li>
                                        )}
                                        {filteredProjects.predefinedPrompts.length > 0 && (
                                            <li className="p-2">
                                                <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
                                                    Example prompts
                                                </h2>
                                                <ul className="text-sm text-gray-700">
                                                    {filteredProjects.predefinedPrompts.map(project => (
                                                        <Combobox.Option
                                                            key={project.name}
                                                            value={project.id}
                                                            className={({active}) =>
                                                                classNames(
                                                                    'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                                                    active && 'bg-cyan-900/80 text-white'
                                                                )
                                                            }
                                                        >
                                                            {({active}) => (
                                                                <>
                                                                    <span
                                                                        className="ml-3 flex-auto">{project.name}</span>
                                                                    {active && <span
                                                                        className="ml-3 flex-none text-cyan-200">Select ...</span>}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))}
                                                </ul>
                                            </li>
                                        )}
                                        {!query && quickActions.length > 0 && (
                                            <li className="p-2">
                                                <h2 className="sr-only">Quick actions</h2>
                                                <ul className="text-sm text-gray-700">
                                                    {quickActions.map(action => (
                                                        <Combobox.Option
                                                            key={action.shortcut}
                                                            value={action}
                                                            className={({active}) =>
                                                                classNames(
                                                                    'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                                                    active && 'bg-cyan-900/80 text-white'
                                                                )
                                                            }
                                                        >
                                                            {({active}) => (
                                                                <>
                                                                    <span
                                                                        className="ml-3 flex-auto">{action.name}</span>
                                                                    <span
                                                                        className={classNames(
                                                                            'ml-3 flex-none text-xs font-semibold',
                                                                            active ? 'text-cyan-400' : 'text-gray-400'
                                                                        )}
                                                                    >
                                                                        <kbd className="font-sans">⌘</kbd>
                                                                        <kbd
                                                                            className="font-sans">{action.shortcut}</kbd>
                                                                    </span>
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))}
                                                </ul>
                                            </li>
                                        )}
                                    </Combobox.Options>}
                                </Combobox>
                            </OutsideClickHandler>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
