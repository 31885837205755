import {useEffect, useState} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import CompletionPage from "./pages/completion";
import CompletionV2Page from "./pages/completionV2/index";
import NoAuthPage from "./pages/auth/noauth";
import CompletionV2ConversationPage from "./pages/completionV2/Chat";
import DataProviderContext from "./contexts/DataProviderContext";
import {GET} from "./services/REST";
import {HmacContextProvider} from "./contexts/HmacContext";
import CompletionV2Provider from "./contexts/CompletionV2Context";
import {useAuth} from './hooks/useAuth';
import ToastNotifications from "./pages/completionV2/components/ToastNotifications";
import FeedbackForm from "./components/FeedbackForm";
import PersonasProvider from "./contexts/PersonasContext";
import FullPageCanvas from "./components/FullPageCanvas";

function App() {

    const { authed, login, logout } = useAuth();
    const [ user, setUser ] = useState(null);

    const [isValid, setIsValid] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    let isDev = process.env.NODE_ENV === 'development';

    useEffect(() => {
        let url = window.location.href;

        if (isDev) {
            return;
        }

        GET({
            url: 'auth/ai-web-url?url=' + encodeURIComponent(url)
        })
            .then(response => {
                setIsValid(response.status);
            })
            .catch(error => {
                setIsValid(false);
                if (error.message) {
                    setErrorMessage(error.message);
                }

            })
            .finally(() => null);
    }, []);

    let search = window.location.search;
    let params = new URLSearchParams(search);

    if (!authed && isDev) {
        setUser("developer");
        setIsValid(true);
        window.user = "developer";
        login();
    } else if (!authed && params.get("user")) {
        setUser(params.get("user"));

        // TODO: move this to a context
        window.user = params.get("user");

        login();
    }

    function RequireAuth({ children }) {
        if (typeof isValid === 'undefined') {
            return <div style={{margin: 50 + 'px'}}>Not yet...</div>;
        }
        // TODO make the validating mini-screen nicer?
        return (authed === true && isValid === true) ? 
            children : 
            (isValid === null) ? "" : <NoAuthPage error={errorMessage} />;
    }

    return (
        <div className="app">
            <BrowserRouter>
                <HmacContextProvider value={isValid}>
                <FeedbackForm />
                <PersonasProvider>
                <CompletionV2Provider>
                    <DataProviderContext>
                        <FullPageCanvas/>
                        <ToastNotifications/>
                        <Routes>
                            <Route path="/noauth" element={<NoAuthPage />} />
                            <Route path="/" element={<RequireAuth><CompletionV2Page /></RequireAuth>} />
                            <Route path="/conversation/:id?" element={<RequireAuth><CompletionV2ConversationPage /></RequireAuth>} />
                            <Route path="/completion-v1" element={<RequireAuth><CompletionPage /></RequireAuth>} />
                        </Routes>
                    </DataProviderContext>
                </CompletionV2Provider>
                </PersonasProvider>
                </HmacContextProvider>
            </BrowserRouter>
        </div>
    )
}

export default App;
