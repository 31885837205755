import {useEffect, useMemo, useState} from "react";
import GlobalEvents from "../../../events/GlobalEvents";

export default () => {
    const [notices, setNotices] = useState([]);
    const [expired, setExpired] = useState([]);

    useEffect(() => {
        GlobalEvents.interpret('SHOW_TOAST_NOTICE', data => {
            const index = String(Math.random(8)).replace('.', '');
            setTimeout(() => expire(index), 10000);

            setNotices(noticeList => ([
                ...noticeList,
                {...data, index: index}
            ]));
        });
    }, []);

    useEffect(() => {
        setNotices(notcs => JSON.parse(JSON.stringify(notcs.map(n => {
            if (expired.includes(n.index)) {
                n.expired = true;
            }
            return n;
        }))));
    }, [expired]);

    const expire = (key, c) => {
        setExpired(e => ([...e, key]));
    };

    const domElements = useMemo(() => {
        const items = [];

        notices.forEach(notice => {
            if (!expired.includes(notice.index)) {
                items.push(
                    <div
                        key={notice.index}
                        className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-cyan-400" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">{notice.content}</p>
                                </div>
                                <div
                                    className="ml-4 flex flex-shrink-0"
                                    onClick={() => expire(notice.index)}
                                >
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-900 focus:ring-offset-2"
                                    >
                                        <span className="sr-only">Close</span>
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                                             aria-hidden="true">
                                            <path
                                                d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });

        return items;
    }, [notices]);

    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[10000]"
        >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                {domElements}
            </div>
        </div>
    );
}