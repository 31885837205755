import {Fragment, useState, useEffect, useContext, useMemo, useRef} from 'react'
import {Combobox, Dialog, Transition} from '@headlessui/react'
import {ArrowRightIcon} from '@heroicons/react/24/outline'
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import OutsideClickHandler from 'react-outside-click-handler';
import {ChevronRightIcon} from '@heroicons/react/20/solid'
import {DataContext} from "../../../contexts/DataProviderContext";

const classNames = (...classes) => classes.filter(Boolean).join(' ');

export default function ContextPicker() {
    const V2Context = useContext(CompletionV2Context);
    const [focus, setFocus] = useState(false);
    const [query, setQuery] = useState('');
    const [personaType, setPersonaType] = useState('single');
    const [multiplePersonas, setMultiplePersonas] = useState({});
    const [showAllPersonas, setShowAllPersonas] = useState(false);

    const Data = useContext(DataContext);
    const personas = useMemo(() => Data.personas, []);
    const teams = [];

    const lastPersona = false;

    const inputRef = useRef();

    const removeDuplicateArrayElements = function(array) {
        return array.filter(function (v, i, self) {
            return i === self.indexOf(v)
        });
    }

    const filteredPersonas = useMemo(() => {
        if (['single', 'multiple'].indexOf(personaType) >= 0) {
            if (query === '') {
                return []
            }
            let filteredPersonas = personas.filter((persona) => {
                return persona.id == 'default'
            })
            query?.split(' ').forEach(function(queryTerm){
                if (queryTerm.length > 0) {
                    filteredPersonas = filteredPersonas.concat(personas.filter((persona) => {
                        return persona.id !== 'default' && (persona.name.toLowerCase().indexOf(queryTerm.toLowerCase()) > -1 || persona.keywords?.toLowerCase().indexOf(queryTerm.toLowerCase()) > -1)
                    }))
                }
            })
            return removeDuplicateArrayElements(filteredPersonas)
        }
        // if ('team' === personaType) {
        //     return query === ''
        //         ? []
        //         : teams.filter((team) => {
        //             return team.name.toLowerCase().includes(query.toLowerCase())
        //         });
        // }
    }, [query, personaType]);

    useEffect(() => {
        if ('single' === personaType) {
            setMultiplePersonas({});
        }
    }, [personaType]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const persona = params.get('persona');
        if (persona && query === '') {
            setQuery(persona)
            setFocus(true)
            // const search = Data.personas?.find(x => x.id === persona);
            // if (search) {
            //     setPersona(search);
            // }
        }
    }, [personas]);

    const setPersona = event => {
        if (event?.id) {
            if ('single' === personaType) {
                // V2Context.setCurrentTeamPersona(null);
                setMultiplePersonas({});
                V2Context.setCurrentMultiPersonas([]);
                Data.confirmSettings({persona: event.id, multiPersonas: [], teamPersona: null})
                V2Context.setCurrentPersona(event);
                setFocus(false);
                setQuery('');
                setShowAllPersonas(false);
                if (V2Context.currentFile?.files?.length > 1 && event.id === 'default') {
                    V2Context.setCurrentFile({files: []});
                }
            }

            if ('multiple' === personaType) {
                V2Context.setCurrentPersona(null);
                // V2Context.setCurrentTeamPersona(null);
                setMultiplePersonas(prsns => {
                    const checked = prsns[event?.id];
                    if (checked) {
                        delete prsns[event?.id];
                    } else {
                        prsns[event?.id] = event
                    }
                    return prsns
                });
                setFocus(true);
                setQuery('');
                setShowAllPersonas(false);
            }

            // if ('team' === personaType) {
            //     V2Context.setCurrentPersona(null);
            //     setMultiplePersonas({});
            //     V2Context.setCurrentMultiPersonas([]);
            //     // V2Context.setCurrentTeamPersona(event);
            //     Data.confirmSettings({persona: null, multiplePersonas: [], teamPersona: event})
            //     setFocus(false);
            //     setQuery('');
            //     if (V2Context.currentFile?.files?.length > 1 && event.id === 'default') {
            //         V2Context.setCurrentFile({files: []});
            //     }
            // }
        }
    };

    const finalizeMultiplePersonas = () => {
        const selectedPersonas = Object.keys(multiplePersonas).filter(p => !!multiplePersonas[p]);

        if (selectedPersonas.length < 2) {
            alert('Select at-least 2 personas');
            return;
        }

        Data.confirmSettings({persona: null, multiPersonas: selectedPersonas, teamPersona: null})
        V2Context.setCurrentPersona(null);
        V2Context.setCurrentMultiPersonas(selectedPersonas);
        setFocus(false);
        setQuery('');
    }

    return (
        <Transition.Root
            id="persona-v2-headless-combobox"
            show={true}
            as={Fragment}
            appear
        >
            <Dialog
                id="persona-v2-headless-combobox-div"
                as="div"
                className="relative z-10 m-auto"
                onClose={() => null}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className=" inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
                </Transition.Child>

                <div className=" w-screen z-10 p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className="mx-auto max-w-45 transform rounded-xl bg-white p-2 shadow-md ring-1 ring-black ring-opacity-10 transition-all"
                        >
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    if (focus) {
                                        V2Context.setCurrentPersona(lastPersona);
                                        setMultiplePersonas({});
                                        V2Context.setCurrentMultiPersonas([]);
                                        setQuery('');
                                        setFocus(false);
                                        setShowAllPersonas(false);
                                    }
                                }}
                            >
                                {(V2Context.currentPersona || (V2Context.currentMultiPersonas.length > 0)) && (
                                    V2Context.currentPersona ? (
                                    <Combobox>
                                        <div className="relative">
                                            {V2Context.currentPersona.image &&
                                                <img
                                                    src={V2Context.currentPersona.image}
                                                    alt=""
                                                    className="pointer-events-none absolute left-3 top-3 h-6 w-6 text-gray-400 flex-none rounded-full"
                                                />
                                            }
                                            {!V2Context.currentPersona.image && V2Context.currentPersona.type !== 'team' &&
                                                <svg xmlns="http://www.w3.org/2000/svg" className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                    <path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                    <path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                </svg>
                                            }
                                            {!V2Context.currentPersona.image && V2Context.currentPersona.type === 'team' &&
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                style={{display: 'block', margin: 'auto'}}
                                                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                                aria-hidden="true" width="1em"
                                                fill="currentColor" viewBox="0 0 30 24">
                                                    <g transform="translate(-2, 0)">
                                                        <path opacity="0.5" d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                        <path opacity="0.5" fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                        <path opacity="0.5" d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                    </g>
                                                    <g transform="translate(10, 0)">
                                                        <path opacity="1" d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                        <path opacity="1" fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                        <path opacity="1" d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                    </g>
                                                </svg>
                                            }
                                            <Combobox.Input
                                                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                                value={V2Context.currentPersona.name}
                                                readOnly={true}
                                                autoComplete={'off'}
                                                onClick={() => {
                                                    V2Context.setCurrentPersona(null);
                                                    setMultiplePersonas({});
                                                    V2Context.setCurrentMultiPersonas([]);
                                                    // V2Context.setCurrentTeamPersona(null);
                                                    setFocus(true);
                                                }}
                                                placeholder="Choose Ella's persona ..."
                                            />
                                        </div>
                                    </Combobox>
                                    ) : (
                                        <Combobox>
                                            <div className="relative">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                    <path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                    <path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                </svg>
                                                <Combobox.Input
                                                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                                    value={V2Context.currentMultiPersonas.map(pId => personas.find(p => p.id === pId).name).join(', ')}
                                                    readOnly={true}
                                                    autoComplete={'off'}
                                                    onClick={() => {
                                                        V2Context.setCurrentPersona(null);
                                                        setMultiplePersonas({});
                                                        V2Context.setCurrentMultiPersonas([]);
                                                        // V2Context.setCurrentTeamPersona(null);
                                                        setFocus(true);
                                                    }}
                                                    placeholder="Choose Ella's persona ..."
                                                />
                                            </div>
                                        </Combobox>
                                    )
                                )}
                                {/* Show Team Personas list */}
                                {false && V2Context.currentTeamPersona && (
                                    <Combobox>
                                        <div className="relative">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                <path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                <path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                            </svg>
                                            <Combobox.Input
                                                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                                value={V2Context.currentTeamPersona.name}
                                                readOnly={true}
                                                autoComplete={'off'}
                                                onClick={() => {
                                                    V2Context.setCurrentPersona(null);
                                                    setMultiplePersonas({});
                                                    V2Context.setCurrentMultiPersonas([]);
                                                    V2Context.setCurrentTeamPersona(null);
                                                    setFocus(true);
                                                }}
                                                placeholder="Choose Ella's persona ..."
                                            />
                                        </div>
                                    </Combobox>
                                )}

                                {(!V2Context.currentPersona && (V2Context.currentMultiPersonas.length <= 0)) && (
                                    <Combobox onChange={event => setPersona(event)}>
                                        {({activeOption}) => (
                                            <>
                                                {false && focus && (
                                                    <div className={"mx-2 rounded-lg p-4 bg-gray-50"}>
                                                        <label className="text-base font-semibold text-gray-900">Select Conversation Type</label>
                                                        <fieldset className="mt-2">
                                                            <legend className="sr-only">Select</legend>
                                                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                                                <div
                                                                    className="flex items-center"
                                                                    onClick={() => {setPersonaType('single')}}
                                                                >
                                                                    <input
                                                                        id="single-persona"
                                                                        name="type-of-persona"
                                                                        type="radio"
                                                                        checked={'single' === personaType}
                                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-transparent"
                                                                        onChange={() => null}
                                                                    />
                                                                    <label htmlFor="single-persona" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                                        Personas
                                                                    </label>
                                                                </div>
                                                                {/*<div*/}
                                                                {/*    className="flex items-center"*/}
                                                                {/*    onClick={() => setPersonaType('multiple')}*/}
                                                                {/*>*/}
                                                                {/*    <input*/}
                                                                {/*        id="multiple-persona"*/}
                                                                {/*        name="type-of-persona"*/}
                                                                {/*        type="radio"*/}
                                                                {/*        checked={'multiple' === personaType}*/}
                                                                {/*        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-transparent"*/}
                                                                {/*        onChange={() => null}*/}
                                                                {/*    />*/}
                                                                {/*    <label htmlFor="multiple-persona" className="ml-3 block text-sm font-medium leading-6 text-gray-900">*/}
                                                                {/*        Multiple Persona*/}
                                                                {/*    </label>*/}
                                                                {/*</div>*/}
                                                                <div
                                                                    className="flex items-center"
                                                                    onClick={() => setPersonaType('team')}
                                                                >
                                                                    <input
                                                                        id="team-persona"
                                                                        name="type-of-persona"
                                                                        type="radio"
                                                                        checked={'team' === personaType}
                                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-transparent"
                                                                        onChange={() => null}
                                                                    />
                                                                    <label htmlFor="team-persona" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                                        Teams
                                                                    </label>
                                                                </div>
                                                                {/*{'multiple' === personaType && (*/}
                                                                {/*    <div>*/}
                                                                {/*        <ArrowRightIcon*/}
                                                                {/*            className={'h-4 w-4 cursor-pointer'}*/}
                                                                {/*            title={'Select'}*/}
                                                                {/*            onClick={() => finalizeMultiplePersonas()}*/}
                                                                {/*        />*/}
                                                                {/*    </div>*/}
                                                                {/*)}*/}
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                )}
                                                <div className="relative">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
                                                        <path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                        <path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                        <path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                    </svg>
                                                    <Combobox.Input
                                                        ref={inputRef}
                                                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                                        placeholder={V2Context.currentPersona ? V2Context.currentPersona.name : (lastPersona?.name ? lastPersona.name : "What are you working on? (eg. manuscript, quizzes, instructor resources)")}
                                                        autoComplete={'off'}
                                                        onChange={(event) => {setQuery(event.target.value);setFocus(true);}}
                                                        onClick={() => setFocus(true)}
                                                        value={query}
                                                    />
                                                </div>

                                                {focus && (showAllPersonas || filteredPersonas.length > 0) && (
                                                    <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
                                                        <div
                                                            className={classNames(
                                                                'max-h-[50vh] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                                                                activeOption && 'sm:h-66'
                                                            )}
                                                        >
                                                            <div className="-mx-2 text-sm text-gray-700">
                                                                {(query === '' ? ('team' === personaType ? teams : personas) : filteredPersonas).map((persona) => (
                                                                    <Combobox.Option
                                                                        as="div"
                                                                        key={persona.id}
                                                                        value={persona}
                                                                        disabled={persona.isAvailable === false || ('multiple' === personaType && !persona.id.startsWith('asst_'))}
                                                                        className={({active}) =>
                                                                            classNames(
                                                                                'flex cursor-default select-none items-center rounded-md p-2',
                                                                                active && 'bg-gray-100 text-gray-900',
                                                                                (persona.isAvailable === false || ('multiple' === personaType && !persona.id.startsWith('asst_'))) && 'opacity-50'
                                                                            )
                                                                        }
                                                                    >
                                                                        {({active}) => (
                                                                            <>
                                                                            {'multiple' === personaType && (
                                                                                <input
                                                                                    id={"select-multiple-" + persona.id}
                                                                                    name="select-multiple-checkbox"
                                                                                    type="checkbox"
                                                                                    checked={!!multiplePersonas[persona.id]}
                                                                                    className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                                    onChange={() => null}
                                                                                />
                                                                            )}
                                                                                {persona.image &&
                                                                                    <img
                                                                                        src={persona.image}
                                                                                        alt=""
                                                                                        className="h-6 w-6 flex-none rounded-full"
                                                                                    />
                                                                                }
                                                                                {!persona.image && persona.type !== 'team' &&
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                         style={{
                                                                                             display: 'block',
                                                                                             margin: 'auto'
                                                                                         }}
                                                                                         className="pointer-events-none h-5 w-6 text-gray-400"
                                                                                         aria-hidden="true" width="1em"
                                                                                         height="1em" fill="currentColor"
                                                                                         viewBox="0 0 24 24"
                                                                                    >
                                                                                        <path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                                                        <path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                                                        <path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                                                    </svg>
                                                                                }
                                                                                {!persona.image && persona.type === 'team' &&
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    style={{display: 'block', margin: 'auto'}}
                                                                                    className="pointer-events-none h-5 w-6 text-gray-400"
                                                                                    aria-hidden="true" width="1em"
                                                                                    fill="currentColor" viewBox="0 0 30 24">
                                                                                        <g transform="translate(-2, 0)">
                                                                                            <path opacity="0.5" d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                                                            <path opacity="0.5" fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                                                            <path opacity="0.5" d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                                                        </g>
                                                                                        <g transform="translate(10, 0)">
                                                                                            <path opacity="1" d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                                                            <path opacity="1" fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                                                            <path opacity="1" d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                                                        </g>
                                                                                    </svg>
                                                                                }
                                                                                <span className="ml-3 flex-auto truncate">{persona.name}</span>
                                                                                {active && (
                                                                                    <ChevronRightIcon
                                                                                        className="ml-3 h-5 w-5 flex-none text-gray-400"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        {activeOption && (
                                                            <div className="hidden h-66 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                                                                <div className="flex-none p-6 text-left">
                                                                    <figure>
                                                                        {activeOption.image &&
                                                                            <img src={activeOption.image} alt="" className="mx-auto h-16 w-16 rounded-full"/>}
                                                                        {!activeOption.image && activeOption.type !== 'team' &&
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 style={{display: 'block', margin: 'auto'}}
                                                                                 className="pointer-events-none cognella-dark"
                                                                                 aria-hidden="true" width="4em" height="4em"
                                                                                 fill="currentColor" viewBox="0 0 24 24"
                                                                            >
                                                                                <path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                                                <path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                                                <path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                                            </svg>
                                                                        }
                                                                        {!activeOption.image && activeOption.type === 'team' &&
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                style={{display: 'block', margin: 'auto'}}
                                                                                className="pointer-events-none cognella-dark"
                                                                                aria-hidden="true" width="5em" height="4em"
                                                                                fill="currentColor" viewBox="0 0 30 24">                                                                            
                                                                                <g transform="translate(-2, 0)">
                                                                                    <path opacity="0.5" d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                                                    <path opacity="0.5" fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                                                    <path opacity="0.5" d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                                                </g>
                                                                                <g transform="translate(10, 0)">
                                                                                    <path opacity="1" d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                                                    <path opacity="1" fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                                                    <path opacity="1" d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                                                </g>
                                                                            </svg>
                                                                        }
                                                                        {/* <figcaption class="cognella-dark font-bold">{activeOption.initials}</figcaption> */}
                                                                    </figure>
                                                                    <br/>
                                                                    <h2 className="font-semibold text-gray-900 text-sm">{activeOption.name}</h2>
                                                                    <p className="text-sm leading-6 text-gray-500">{activeOption.description}</p>
                                                                    {{activeOption}.highlightText !== false && <p className="mt-2 text-sm leading-6 text-cyan-700">{activeOption.hightlightText}</p>}
                                                                </div>
                                                                {/* activeOption.isAsync && ( */}
                                                                {/*    <div className="flex-none px-6 pb-2 text-left"> */}
                                                                {/*        {activeOption.isAsync && */}
                                                                {/*            <p className="mt-2 text-sm leading-6 text-green-700">Asynchronous process</p>} */}
                                                                {/*    </div> */}
                                                                {/*) */}
                                                                {activeOption.requirements && activeOption.prompt && (
                                                                    <div
                                                                        className="flex flex-auto flex-col justify-between p-6">
                                                                        <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                                                            <dt className="col-end-1 font-semibold text-gray-900 text-xs">Input file<br/>expectations</dt>
                                                                            <dd>{activeOption.requirements}</dd>
                                                                            <dt className="col-end-1 font-semibold text-gray-900 text-xs">Sample<br/>prompt</dt>
                                                                            <dd>{activeOption.prompt}</dd>
                                                                        </dl>
                                                                    </div>)}
                                                            </div>
                                                        )}
                                                    </Combobox.Options>
                                                )}

                                                {query !== '' && filteredPersonas.length === 0 && (
                                                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             style={{display: 'block', margin: 'auto'}}
                                                             className="pointer-events-none cognella-dark"
                                                             aria-hidden="true" width="4em" height="4em" fill="currentColor"
                                                             viewBox="0 0 24 24"
                                                        >
                                                            <path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                                                            <path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path>
                                                            <path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path>
                                                        </svg>
                                                        <p className="mt-4 font-semibold text-gray-900">No persona found</p>
                                                        <p className="mt-2 text-gray-500">
                                                            We couldn’t find anything with that term. Please try again.
                                                        </p>
                                                    </div>
                                                )}
                                                {(query !== '' || !showAllPersonas) && (<div 
                                                    className="flex flex-wrap items-center px-3 py-2.5 text-xs cognella-dark cursor-pointer"
                                                    onClick={(e)=>{setFocus(true);setQuery('');setShowAllPersonas(true);}}
                                                >
                                                    Show all personas</div>
                                                )}
                                            </>
                                        )}
                                    </Combobox>
                                )}
                            </OutsideClickHandler>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
};
