import {useContext, useEffect, useRef, useState} from "react";
import Conversation from "./Conversation";
import SendButton from "./SendButton";
import {DataContext} from "../../../contexts/DataProviderContext";
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import Initializing from "./Initializing";

export default () => {

    const Data = useContext(DataContext);
    const V2Context = useContext(CompletionV2Context);

    const inputRef = useRef();

    const [doSuccessCall, setDoSuccessCall] = useState(false);
    const [runStatus, setRunStatus] = useState(null);

    useEffect(() => {
        if (doSuccessCall) {
            submitPrompt();
            setDoSuccessCall(false);
        }
    }, [doSuccessCall]);

    useEffect(() => {
        if (V2Context.conversationId) {
            setDoSuccessCall(true);
        }
    }, [V2Context.conversationId]);

    const updateHeight = element => {
        if (element.value && element.value.split('\n').length > 1) {
            element.rows = element.value.split('\n').length;
        } else {
            element.rows = 1;
        }
    }

    useEffect(() => {
        if (inputRef.current) {
            if (inputRef.current.value === '\n') {
                V2Context.setCurrentPrompt('');
            } else {
                updateHeight(inputRef.current);
            }
        }
    }, [V2Context.currentPrompt]);

    const submitPrompt = () => {
        if (Data.loadingConversations || Data.loadingPrompt) {
            return;
        }
        const prompt = V2Context.currentPrompt;
        V2Context.setCurrentPrompt('');
        if (prompt && prompt.length > 0) {
            Data.sendVectorPrompt(prompt, response => {
                V2Context.setCurrentPrompt(response.status ? '' : prompt);
                if (response.status && response.run_status) {                    
                    V2Context.setAssistantRunStatus(response.run_status);
                    setRunStatus(response.run_status);
                }
            });
        }
    };

    useEffect(() => {
        if (runStatus && runStatus !== ' ') {
            let waitTime = 10000;
            setTimeout(() => {
                setRunStatus(' ');
                Data.pingAssistant(response => {
                    if (response.status && response.run_status) {
                        if (Data.personaRunStatusesForWait.indexOf(response.run_status) >= 0) {
                            V2Context.setAssistantRunStatus(response.run_status);
                            setRunStatus(response.run_status);
                        } else {
                            V2Context.setAssistantRunStatus(null);
                            setRunStatus(null)
                        }
                    }
                });
            }, waitTime);
        }         
    }, [runStatus]);

    useEffect(() => {
        if (V2Context.assistantRunStatus !== runStatus) {
            setRunStatus(V2Context.assistantRunStatus);
        }
    }, [V2Context.assistantRunStatus]);

    const getLastPrompt = () => {
        let lastPrompt = '';

        Data.currentConversation.chatHistory.forEach(chat => {
            if (chat.role === 'user') {
                lastPrompt = chat.content;
            }
        });

        return lastPrompt;
    };


    return (
        Data.loadingConversations
            ? <div className="flex h-full">
                <Initializing/>
            </div>
            : <div className="relative h-[100%]">
                <div className="flex mb-4 h-[90%] overflow-y-auto">
                    <Conversation/>
                </div>
                <div className="flex absolute bottom-1 left-0 items-center h-auto bg-white w-full px-2">
                    <div className="flex-grow">
                        <div className="relative w-full">
                            <textarea
                                ref={inputRef}
                                rows={1}
                                value={V2Context.currentPrompt}
                                className="w-full rounded-xl bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm overflow-y-scroll max-h-20 main-conversation-prompt"
                                placeholder={'Ask your prompt here'}
                                onChange={e => {
                                    V2Context.setCurrentPrompt(e.target.value);
                                    updateHeight(e.target);
                                }}
                                onKeyUp={e => {
                                    if (!V2Context.currentPrompt && (e.code === 'ArrowUp')) {
                                        V2Context.setCurrentPrompt(getLastPrompt())
                                    }
                                }}
                                onKeyDown={e => {
                                    if (!e.shiftKey && (e.code === 'Enter')) {
                                        e.preventDefault();
                                        submitPrompt();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="ml-4">
                        <SendButton onClick={submitPrompt}/>
                    </div>
                </div>
            </div>
    );
};