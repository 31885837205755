import {Fragment, useContext, useEffect, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import {GET, PUT} from "../../../services/REST";

export default () => {
    const V2Context = useContext(CompletionV2Context);

    const [open, setOpen] = useState(false);

    const inputFieldRef = useRef(null);
    const cancelButtonRef = useRef(null);

    const [payload, setPayload] = useState(null);

    const [timer, setTimer] = useState(0); // In seconds
    const [runTimer, setRunTimer] = useState(false); // In seconds

    useEffect(() => {
        let timeout = null;
        if (V2Context.conversationId && V2Context.conversationLoaded && ['queued', 'in_progress'].indexOf(V2Context.assistantRunStatus) >= 0) {
            timeout = setInterval(() => {
                GET({
                    url: 'chat-from-files/v2/conversation/' + V2Context.conversationId + '/intervention'
                }).then(res => {
                    if (res.status && res.content) {
                        setPayload(res.content);
                    } else {
                        setPayload(null);
                    }
                }).catch(() => {
                    setPayload(null);
                });
            }, 5000);
        }

        return () => {
            setPayload(null);
            clearInterval(timeout);
        };
    }, [V2Context.conversationId, V2Context.conversationLoaded, V2Context.assistantRunStatus]);

    useEffect(() => {
        let interval = null;

        if (payload) {
            const waitingTime = 60; // In seconds
            let remainingTime = waitingTime - parseInt((new Date().getTime() - new Date(payload.created_at).getTime()) / 1000);

            if (remainingTime >= 0) {
                interval = setInterval(() => {
                    remainingTime--;
                    setTimer(remainingTime);
                    setRunTimer(true);
                },1000);
            } else {
                clearInterval(interval);
                setTimer(0);
                setRunTimer(false);
            }
            setOpen(true);
        } else {
            setOpen(false);
        }

        return () => clearInterval(interval);
    }, [payload]);

    const sendResponse = (fValue = null) => {
        if (payload) {
            let queryResponse = null;

            if (fValue) {
                queryResponse = fValue;
            } else if (inputFieldRef.current) {
                queryResponse = inputFieldRef.current.value
            }

            PUT({
                url: 'chat-from-files/v2/conversation/' + V2Context.conversationId + '/intervention/' + payload.id,
                content: {
                    message: queryResponse
                }
            }).then(res => {
                if (res && res.status) {
                    setPayload(null);
                }
            }).catch(() => null);
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={inputFieldRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full justify-content-end align-items-end p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                {payload && (
                                    <>
                                        <div className="sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                            </div>
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <div className="my-2">
                                                    <p className="text-sm">
                                                        {runTimer && timer >= 0 && (
                                                            <span
                                                                className={[
                                                                    (timer < 10) ? 'text-red-700' : '',
                                                                    (timer >= 10 && timer < 30) ? 'text-orange-700' : '',
                                                                    (timer >= 30) ? 'text-cyan-700' : '',
                                                                ].join(' ')}
                                                            >
                                                            {timer} seconds left to respond
                                                        </span>
                                                        )}
                                                    </p>
                                                </div>
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    {payload.query}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    {(!payload.structure || payload.structure?.type === 'text') && (
                                                        <input
                                                            type="text"
                                                            ref={inputFieldRef}
                                                            className="block w-full pt-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            onKeyDown={event => {
                                                                if (event.code === 'Enter') {
                                                                    event.preventDefault();
                                                                    sendResponse();
                                                                }
                                                            }}
                                                            placeholder="Write your response and press ENTER"
                                                        />
                                                    )}
                                                    {(payload.structure && payload.structure?.type === 'options') && (
                                                        <div className="space-x-3 pt-3">
                                                            {payload.structure?.options.map(item => (
                                                                <button
                                                                    type="button"
                                                                    className="bg-white px-3.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                    onClick={() => sendResponse(item)}
                                                                >
                                                                    {item}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            {(!payload.structure || payload.structure?.type === 'text') && (
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md bg-cyan-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                                    onClick={() => sendResponse()}
                                                >
                                                    Respond
                                                </button>
                                                )}
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
