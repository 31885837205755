import {useContext, useEffect} from 'react'
import {Bars3Icon} from '@heroicons/react/24/outline'
import ConversationScreen from "./components/ConversationScreen";
import GlobalEvents from "../../events/GlobalEvents";
import ConversationListOnSideList from "./components/ConversationsListOnSideList";
import ConversationContextTopbar from "./components/ConversationContextTopbar";
import {useParams} from "react-router-dom";
import {CompletionV2Context} from "../../contexts/CompletionV2Context";
import {GET} from "../../services/REST";
import {DataContext} from "../../contexts/DataProviderContext";
import SourceDetails from "./components/SourceDetails";
import FilesList from "./components/FilesList";
import HumanInterventionAlert from "./components/HumanInterventionAlert";

export default () => {
    const {id} = useParams();
    const Data = useContext(DataContext);
    const V2Context = useContext(CompletionV2Context);

    useEffect(() => {
        require('./tailwind-base.css');

        if (id && V2Context.conversationId !== id && !V2Context.conversationLoaded) {
            V2Context.setAssistantRunStatus(null);
            Data.setLoadingConversations(true);
            GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'retrieve-conversation', show: true, message: 'Retrieving Conversation', description: 'Loading conversation for you...'});
            GET({
                url: 'chat-from-files/v2/conversation/' + id
            })
                .then(response => {
                    if (response.status) {
                        const {text, ...rFile} = response.content?.initialContext;
                        V2Context.setConversationId(response.content.conversationId);
                        V2Context.setConversationMetadata(response.content.conversationMetadata);
                        V2Context.setConversationLoaded(true);
                        V2Context.setVectorMode(response.content.isVectorized);
                        rFile.files = rFile?.files?.map(file => {
                            if (file.id === response.content?.conversationMetadata?.fileId) {
                                file.metadata = response.content?.conversationMetadata;
                            }
                            return file;
                        });
                        V2Context.setCurrentFile(rFile);
                        Data.currentConversation.setDocument(rFile);
                        Data.currentConversation.setChatHistory(response.content.conversations);
                        Data.setEngine(response.content.modelDetails.engine);
                        Data.setInstructions(response.content.instructions);
                        Data.setInitialContext(text ?? '');
                        Data.setLoadingConversations(false);
                        V2Context.setAssistantRunStatus(response.content.conversationMetadata?.run_status);
                        if (response.content.conversationMetadata?.personaId) {
                            V2Context.setCurrentPersona(Data.personas?.find(x => x.id === response.content.conversationMetadata?.personaId) ?? {})
                        }
                        GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'retrieve-conversation', show: false});
                    } else {
                        GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: response.message})
                        GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'retrieve-conversation', show: true, message: 'Error', description: response.message, showReturn: true});
                    }
                })
                .catch(error => {
                    GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message})
                    GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'retrieve-conversation', show: true, message: 'Error', description: error.message, showReturn: true});
                });
        }
    }, [window.location.pathname]);

    const setSidebarOpen = state => GlobalEvents.dispatch('OPEN_SIDENAV_BAR', {state: state});

    return (
        <div>
            <ConversationListOnSideList/>
            <div className="flex-col h-screen w-screen">
                <div className="sticky h-16 top-0 z-40 lg:mx-auto">
                    <div
                        className="flex items-center h-full gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none"
                    >
                        <button
                            type="button"
                            className="-m-2.5 p-2.5 text-gray-700"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                        </button>

                        {/* For larger screens only */}
                        <div className="flex h-16 shrink-0 items-center hidden lg:block">
                            <img
                                className="h-8 mt-3 w-auto"
                                src="https://cognella.com/wp-content/uploads/2014/09/color650px.png"
                                alt="Cognella"
                            />
                        </div>
                        {/* For smaller screens only */}
                        <div className="h-16 shrink-0 items-center lg:hidden">
                            <img
                                className="h-8 mt-3 w-auto"
                                src="/cognella_icon.jpg"
                                alt="Cognella"
                            />
                        </div>

                        {/* Separator */}
                        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true"/>

                        <ConversationContextTopbar/>
                    </div>
                </div>

                <div className="relative flex-col h-[calc(100vh-4rem)] w-full gap-y-1">
                    <ConversationScreen/>
                </div>
            </div>
            <SourceDetails/>
            <FilesList/>
            <HumanInterventionAlert/>
        </div>
    )
}
