
const GlobalEvents = {
    dispatch: (eventName, data) => {
        document.dispatchEvent(
            new CustomEvent(`GlobalEvent.${eventName}`, {
                detail: {
                    data: data
                }
            })
        );
    },

    interpret: (eventName, callback) => {
        document.addEventListener(`GlobalEvent.${eventName}`, event => callback(event.detail.data));
    },
};

export default GlobalEvents;