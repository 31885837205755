import {DataContext} from "../../../contexts/DataProviderContext";
import {useContext, useEffect, useRef} from "react";
import User from "./messages/User";
import Assistant from "./messages/Assistant";
import MessageContextMenu from "./MessageContextMenu";
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import AltTextGenerationTask from "./messages/tasks/AltTextGenerationTask";
import AltTextGenerationTaskFullDetails from "./messages/tasks/AltTextGenerationTaskFullDetails";

export default () => {
    const Data = useContext(DataContext);
    const V2Context = useContext(CompletionV2Context);
    const lastConvRef = useRef();
    const tasks = {};

    useEffect(() => {
        lastConvRef.current && lastConvRef.current.scrollIntoView({behavior: 'smooth'});
    }, [Data.currentConversation.chatHistory]);

    return (
        <div className="flex flex-col h-full w-full">
            <div className="grid grid-cols-12 gap-y-2 conversations">
                {Data.currentConversation.chatHistory
                    .map((record, index) => {
                        if ('user' === record.role) {
                            if (record?.metadata?.hasOwnProperty('taskInfo')) {
                                tasks[record.metadata?.run_id] = record.metadata.taskInfo
                            }
                            return (
                                <User
                                    key={index}
                                    index={index}
                                    record={record}
                                    previous={Data.currentConversation.chatHistory[index - 1]}
                                    next={Data.currentConversation.chatHistory[index + 1]}
                                    created_at={record.created_at}
                                />
                            );
                        } else if ('assistant' === record.role) {
                            let components = [
                                <Assistant
                                    key={index}
                                    index={index}
                                    record={record}
                                    previous={Data.currentConversation.chatHistory[index - 1]}
                                    next={Data.currentConversation.chatHistory[index + 1]}
                                    created_at={record.created_at}
                                />
                            ];

                            if (tasks[record?.metadata?.run_id]) {
                                if ('alt_text_generation' === tasks[record.metadata.run_id]?.taskType) {
                                    components.push(<AltTextGenerationTask key={'task_'+index} details={{...tasks[record.metadata.run_id]}}/>);
                                }
                                delete tasks[record.metadata.run_id]
                            }
                            return components;
                        }

                        return null;
                    })
                }
                {V2Context.assistantRunStatus && Data.personaRunStatusesForWait.indexOf(V2Context.assistantRunStatus) >= 0 &&  (
                    <Assistant
                        key={Data.currentConversation.chatHistory.length}    
                        record={{"personaId": V2Context.currentPersona?.id, "role": "assistant"}}
                        previous={Data.currentConversation.chatHistory[-1]}
                    />  
                )}

                <span ref={lastConvRef}/>
            </div>
            <MessageContextMenu/>
            <AltTextGenerationTaskFullDetails/>
        </div>
    );
}