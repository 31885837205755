import GlobalEvents from "../../../events/GlobalEvents";
import {useContext} from "react";
import {PencilFill, Gear} from "react-bootstrap-icons";
import {DataContext} from "../../../contexts/DataProviderContext";
import {Popover, Card, OverlayTrigger} from "react-bootstrap";
import {InfoCircle} from "react-bootstrap-icons";

export default () => {
    const Data = useContext(DataContext);
    const savedFile = localStorage.getItem('file');
    const savedPath = localStorage.getItem('path');
    
    return (
        <Card border={'light'} className={'context-header-card'}>
            <Card.Body>
                <img src={'https://cognella.com/wp-content/uploads/2014/09/color650px.png'} height={50} />
                <Card.Text
                    as={'span'}
                    className={'context-link'}
                >
                    <span
                        onClick={() => GlobalEvents.dispatch('CONTEXT_SELECTOR_SHOW', 'default')}
                    >
                        {Data.currentConversation.document.files.length 
                            ? (
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement={'bottom'}
                                    overlay={
                                        <Popover
                                            id="context-popover"
                                            body
                                        >
                                            {Data.currentConversation.document.files[0].subsection
                                                ? (
                                                    ('string' === typeof Data.currentConversation.document.files[0].subsection)
                                                        ? <>
                                                            {Data.currentConversation.document.files[0].name}
                                                            <br/>
                                                            Page Range: {Data.currentConversation.document.files[0].subsection}
                                                        </>
                                                        : <ul>{Data.currentConversation.document.files[0].subsection.map((section, i) => (
                                                            <li key={i}>{section}</li>
                                                        ))}</ul>
                                                )
                                                : Data.currentConversation.document.files[0].name
                                            }
                                        </Popover>
                                    }
                                >
                                    <span><InfoCircle/>&nbsp;{Data.currentConversation.document.files[0].name}{('string' === typeof Data.currentConversation.document.files[0].subsection) && ` (${Data.currentConversation.document.files[0].subsection})`}</span>
                                </OverlayTrigger>
                            )
                            : 'Select Project File'
                        }
                        &nbsp;
                        <PencilFill title={'Select or update context file'}/>
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span
                        onClick={() => GlobalEvents.dispatch('SETTINGS_SHOW')}
                    >
                        Conversation Settings &nbsp;
                        <Gear title={'Update model and instructions'}/>
                    </span>
                </Card.Text> 
                {savedPath && JSON.parse(savedPath).map((folder) => (
                    folder && <Card.Text as={'span'}
                        className={''}
                    >
                    <span className={'saved-path'}
                        onClick={() => GlobalEvents.dispatch('CONTEXT_SELECTOR_SHOW', folder.id)}
                    > {folder.name} </span>
                    <span className="icon-chevron icon-chevron-right be-breadcrumb-seperator"/>
                    </Card.Text>    
                ))}
                {savedFile && <Card.Text
                    as={'span'}
                    className={'saved-path'}
                >
                    <span
                        onClick={() => GlobalEvents.dispatch('CONTEXT_SELECTOR_SHOW', 'file')}
                    > {JSON.parse(savedFile)?.name}</span>
                </Card.Text>}
                {Data.currentConversation.context && <Card.Text className={'context-content'}>{Data.currentConversation.context}</Card.Text>}
            </Card.Body>
        </Card>
    );
}