import {useContext, useEffect} from "react";
import {BoxSearchContext} from "./index";
import GlobalEvents from "../../../../events/GlobalEvents";

export default () => {
    const Data = useContext(BoxSearchContext);

    useEffect(() => {
        const element = new window.Box.ContentExplorer();
        if (Data.box.token && Data.box.folder) {
            if (Data.selectedFile?.id && localStorage.getItem('file')) {
                let fileInfo = JSON.parse(localStorage.getItem('file'));
                Data.setSelectedFile(fileInfo);
            } else {
                element.addListener('select', (event) => {
                    if (event[0] && event[0].type === 'file') {
                        let fileInfo = {
                            id: event[0].id,
                            name: event[0].name,
                            extension: event[0]?.extension,
                        }, path = [];
                        Data.setSectionsList([]);
                        Data.setSelectedFile(fileInfo);
                        localStorage.setItem('file', JSON.stringify(fileInfo));
                        if (event[0]?.path_collection?.entries) {
                            event[0].path_collection.entries.forEach((entry) => {
                                // const regex = /^\d{5}-\d[A-Za-z]$/;//sku regex
                                if (entry.id > 0) {
                                    const item = {
                                        id: entry.id,
                                        name: entry.name,
                                    };

                                    if (entry?.extension) {
                                        item.extension = entry.extension;
                                    }
                                    path.push(item);
                                }
                            })
                        }
                        if (path.length > 0) {
                            localStorage.setItem('path', JSON.stringify(path));
                        }
                    }
                });

                element.show(Data.box.folder, Data.box.token, {
                    canDelete: false,
                    canPreview: false,
                    canDownload: false,
                    canShare: false,
                    canRename: false,
                    canUpload: true,
                    container: '.box-container',
                    canCreateNewFolder: true,
                    canSetShareAccess: false,
                    logoUrl: 'https://cognella.com/wp-content/uploads/2014/09/color650px.png',
                    size: 'large',
                    // initialPageSize: 25,
                });
            }
        } else {
            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Unable to initiate Box!'});
        }
    }, [Data.box.token, Data.box.folder]);

    return (
        <div className="box-container"/>
    );
};