import React, { useState, useEffect } from 'react';
import {POST} from "../../../../../services/REST";
import GlobalEvents from "../../../../../events/GlobalEvents";

const AltTextGenerationTask = ({details}) => {

    const bookId = details?.taskExtraDetails?.book_id ?? null;
    const subTaskIds = details.subTasksIds;

    const [shouldRetry, setShouldRetry] = useState(true);

    const [tasks, setTasks] = useState({
        total: 0,
        completed: 0,
        success: 0,
        alreadyProcessed: 0,
        notAnImage: 0,
        error: 0,
        remaining: -1,
        percentage: 0,
    });
    const [taskAllDetails, setTaskAllDetails] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchTaskStatus = async () => {
        if (0 === tasks.remaining) {
            return true;
        }

        POST({
            url: 'tasks/get-status',
            content: {
                task_ids: subTaskIds
            }
        }).then(res => {
            if (res.status) {
                const tasksList = res.content.tasks;

                const total = Object.keys(tasksList).length;
                let completed = 0;
                let success = 0;
                let alreadyProcessed = 0
                let notAnImage = 0
                let error = 0;
                let remaining = 0;

                const errorCodes = ['prompt_not_found', 'not_updated', 'openai_assistant_failure', 'error_while_processing'];

                Object.keys(tasksList).forEach(t => {
                    const t1 = tasksList[t];
                    const result = t1?.result?.response;
                    if (t1.status === 'SUCCESS') {
                        completed++;
                        if (result === 'success') {
                            success++;
                        } else if (result === 'already_exists') {
                            alreadyProcessed++;
                        } else if (result === 'not_an_image') {
                            notAnImage++;
                        } else if (errorCodes.includes(result)) {
                            error++;
                        }
                    }

                    if (t1.status === 'PENDING' || t1.status === 'RUNNING') {
                        remaining++;
                    }
                });

                setTaskAllDetails(tasksList);
                setTasks({
                    total: total,
                    completed: completed,
                    success: success,
                    alreadyProcessed: alreadyProcessed,
                    notAnImage: notAnImage,
                    error: error,
                    remaining: remaining,
                    percentage: (completed / total) * 100,
                });

                setError(null);
                setShouldRetry(remaining > 0);
            }
        }).catch(err => {
            setError(err.message ?? 'Unable to fetch task status');
            setShouldRetry(false);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        const pollInterval = setInterval(() => {
            if (!shouldRetry) {
                clearInterval(pollInterval);
                return;
            }
            fetchTaskStatus().then(() => null);
        }, 5000); // Poll every 5 seconds

        // Initial fetch
        fetchTaskStatus().then(() => null);

        // Cleanup function
        return () => clearInterval(pollInterval);
    }, [shouldRetry]);

    if (isLoading || error) {
        return (
            <div className="col-start-1 col-end-12 p-3 rounded-lg">
                <div className="relative flex flex-row items-center">
                    <div className="relative ml-12 text-sm py-2 px-4 shadow rounded-xl bg-white w-full max-w-md">
                        <div>
                            <div className="flex justify-between items-center">
                                {isLoading && (
                                    <h4 className="text-sm font-medium text-cyan-900">
                                        Loading Task Progress
                                    </h4>
                                )}
                                {error && (
                                    <h4 className="text-sm font-medium text-red-600">
                                        {error}
                                    </h4>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="col-start-1 col-end-12 p-3 rounded-lg">
            <div className="relative flex flex-row items-center">
                <div className="relative ml-12 text-sm py-2 px-4 shadow rounded-xl bg-white w-full max-w-md">
                    <div>
                        <div className="flex justify-between items-center">
                            <h4 className="text-sm font-medium text-gray-900">Task Progress</h4>
                            <button
                                className="text-cyan-600 hover:text-cyan-800 text-xs"
                                onClick={() => {
                                    GlobalEvents.dispatch('SHOW_ALT_TEXT_GENERATION_TASK_DETAILS', {
                                        bookId,
                                        tasks,
                                        taskAllDetails
                                    });
                                }}
                            >
                                More Details
                            </button>
                        </div>
                        <div className="mt-2 flex justify-between items-center">
                            <p className="text-xs text-gray-500">{tasks.completed}/{tasks.total} tasks completed</p>
                            <p className="text-xs text-gray-500">{tasks.percentage.toFixed(1)}%</p>
                        </div>
                        <div className="mt-2" aria-hidden="true">
                            <div className="overflow-hidden rounded-full bg-gray-200">
                                <div
                                    className="h-2 rounded-full bg-cyan-600"
                                    style={{ width: `${tasks.percentage}%` }}
                                />
                            </div>
                        </div>
                        <div className="mt-3 grid grid-cols-2 gap-2 text-xs text-gray-500">
                            <div>
                                <p>Successful: <span className="font-medium text-green-600">{tasks.success}</span></p>
                            </div>
                            <div>
                                <p>Already Processed: <span className="font-medium text-yellow-600">{tasks.alreadyProcessed}</span></p>
                            </div>
                            <div>
                                <p>Not an Image: <span className="font-medium text-gray-500">{tasks.notAnImage}</span></p>
                            </div>
                            <div>
                                <p>Error: <span className="font-medium text-red-600">{tasks.error}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AltTextGenerationTask;