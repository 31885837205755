import {Fragment, useState, useEffect, useContext, useRef} from 'react'
import {Combobox, Dialog, Transition} from '@headlessui/react'
import {DocumentDuplicateIcon} from '@heroicons/react/24/outline'
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import OutsideClickHandler from "react-outside-click-handler";

export default function PagePalette() {

    const V2Context = useContext(CompletionV2Context);

    const inputRef = useRef();
    const [focus, setFocus] = useState(false);
    const [validRange, setValidRange] = useState(true);

    useEffect(() => {        
        if (document.activeElement) {
            document.activeElement.blur();
        }
        if (inputRef.current) {
            focus ? inputRef.current.focus() : inputRef.current.blur();
        }
    }, [focus]);

    useEffect(() => {
        setFocus(false);
        setTimeout(() => {
            if (V2Context.currentFile?.files?.length > 0 && V2Context.currentFile?.files[0]?.extension === 'pdf') { 
                if (document.getElementById('page-v2-headless-combobox')) {
                    document.getElementById('page-v2-headless-combobox').style.display = 'block';
                    setFocus(true);   
                }
                if (!V2Context.currentFile?.files[0]?.subsection) {
                    setFocus(true);
                } 
            }
        }, 10);
        if (V2Context.currentFile?.files?.length > 0 && V2Context.currentFile?.files[0]?.extension !== 'pdf') { 
            if (document.getElementById('page-v2-headless-combobox')) {
                document.getElementById('page-v2-headless-combobox').style.display = '';
            }
            setFocus(false);
        }
    }, [V2Context.currentFile?.files?.length, V2Context.currentFile?.files]);    

    const setSubsection = (section) => {
        if (V2Context.currentFile?.files?.length === 0) { return; }
        if (!section) { setValidRange(true); return;}
        //check if section and existing section info is the same
        if (section === V2Context.currentFile?.files[0].subsection) { return; }
        const match = section?.match(/^(\d+|\d+-\d+)(?:,(\d+|\d+-\d+))*$/g);
        if (match?.length > 0) {
            setValidRange(true);
            let existingFiles = V2Context.currentFile.files;
            existingFiles[0].subsection = section;
            V2Context.setCurrentFile({files: existingFiles});
        } else {
            setValidRange(false);
        }
    }

    const clickedInsidePopup = event => {
        const path = event.path || (event.composedPath && event.composedPath());
        if (path && path.length > 0) {
            return path.find(el => el.id && (el.id.includes('page-v2-headless-combobox-div')));
        }
        return false;
    };

    return (
        <>
        <Transition.Root
            id="page-v2-headless-combobox"
            show={true}
            as={Fragment}
            appear
        >
            <Dialog
                id="page-v2-headless-combobox-div"
                as="div"
                className="relative z-10 m-auto hidden"
                onClose={() => null}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
                </Transition.Child>

                <div className={"w-screen z-10"}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div>
                            {V2Context.currentFile?.files?.length > 0 && (
                                <Dialog.Panel
                                    className="mx-auto max-w-45 transform rounded-xl bg-white p-2 shadow-md ring-1 ring-black ring-opacity-10 transition-all"
                                >
                                    <OutsideClickHandler
                                        onOutsideClick={event => {
                                            if (clickedInsidePopup(event)) {
                                                setFocus(true);
                                                return;
                                            }
                                            setFocus(false)
                                            setSubsection(inputRef?.current?.value);
                                        }}
                                    >
                                        <Combobox>
                                            <div className="relative">
                                                <DocumentDuplicateIcon
                                                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                                <input
                                                    type="text"
                                                    ref={inputRef}
                                                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                                    placeholder='(optional) Specify page range (e.g. 1-5,7,10-12)'
                                                    autoComplete={'off'}
                                                    onKeyDown={e => ((e.code === 'Enter') || (e.code === 'Tab') || (e.code === 'NumpadEnter')) && setSubsection(inputRef?.current?.value)}
                                                    onFocus={() => {
                                                        setFocus(true);
                                                    }}
                                                    onClick={() => {
                                                        setFocus(true);
                                                    }}
                                                />
                                                { !validRange && (<span className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-11">
                                                    Invalid page range
                                                </span>
                                                )}
                                            </div>
                                        </Combobox>
                                    </OutsideClickHandler>
                                </Dialog.Panel>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
        </>
    );
}
