import {useContext, useEffect, useState} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ClipboardJS from "clipboard";
import GlobalEvents from "../../../events/GlobalEvents";
import {DataContext} from "../../../contexts/DataProviderContext";

export default () => {
    const Data = useContext(DataContext);
    const [context, setContext] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [xYPosition, setXyPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        GlobalEvents.interpret('SHOW_MESSAGE_CONTEXT_MENU', data => {
            setXyPosition(data.coordinates);
            setCurrentData(data);
            setTimeout(() => setContext(true), 100);
        });

        const clipboard = new ClipboardJS('.copy-card', {
            text: trigger => trigger.dataset.clipboardText
        });

        clipboard.on('success', e => {
            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Content copied to clipboard!'});
            e.clearSelection();
            hideContext();
        });

        return () => {
            clipboard.destroy();
        }
    }, []);

    const hideContext = () => {
        setContext(false);
    };

    return (
        context &&
        <OutsideClickHandler onOutsideClick={() => hideContext()}>
            <div
                style={{
                    top: xYPosition.y+'px',
                    left: xYPosition.x+'px'
                }}
                className={[`fixed rounded-lg text-sm bg-white shadow z-20 cursor-default`]}
            >
                <ul className={'list-none'}>
                    <li>
                        <p
                            className={'copy-card px-2 py-1'}
                            data-clipboard-id={'simple-copy-card'}
                            data-clipboard-text={currentData?.record?.content ?? ''}
                        >
                            Copy
                        </p>
                    </li>
                    <li>
                        <p
                            className={'copy-card px-2 py-1'}
                            data-clipboard-id={'with-context-copy-card'}
                            data-clipboard-text={(() => {
                                let pmt = '';
                                let res = '';

                                if ('user' === currentData?.record?.role) {
                                    pmt = currentData?.record?.content ?? '';
                                    res = currentData.next?.original_content ?? '';
                                } else if ('assistant' === currentData?.record?.role) {
                                    pmt = currentData?.previous?.original_content ?? '';
                                    res = currentData?.record?.content ?? '';
                                }

                                return "[" +
                                    "model = "+Data.settings.model + "\n"+
                                    "prompt = "+pmt + "\n"+
                                    "response = "+res + "\n"+
                                    "]";
                            })()}
                        >
                            Copy prompt and response
                        </p>
                    </li>
                    <li>
                        <p
                            className={'trash-card px-2 py-1'}
                            onClick={() => {
                                let pmt = -1;

                                if ('user' === currentData?.record?.role) {
                                    pmt = currentData.index;
                                } else if ('assistant' === currentData?.record?.role) {
                                    pmt = currentData.index - 1;
                                }

                                if (pmt >= 0) {
                                    Data.removePrompt(pmt);
                                    GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Prompt and response removed from conversation!'});
                                }

                                hideContext();
                            }}
                        >
                            Delete this prompt and response
                        </p>
                    </li>
                </ul>
            </div>
        </OutsideClickHandler>
    )
};