import Markdown from "react-markdown";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {coldarkDark} from "react-syntax-highlighter/dist/cjs/styles/prism";
import remarkGfm from "remark-gfm";
import {LinkIcon} from "@heroicons/react/24/outline";
import GlobalEvents from "../../../../events/GlobalEvents";
import {DataContext} from "../../../../contexts/DataProviderContext";
import {useContext} from "react";

export const FormattedView = prompt => {
    const Data = useContext(DataContext);
    let sender = prompt.user === 'user' ? '__You__' : ('__' + (prompt.persona ? prompt.persona : 'Ella') + '__');
    const newDate = date => {
        const d = new Date(date * 1000);
        return d.toLocaleString();
    };
    if (prompt.created_at) {
        sender += (' _' + newDate(prompt.created_at) + '_ ')
    }
    if (prompt.status) {
        let status = prompt.status;
        if (status === 'in_progress') {
            status = 'in progress';
        }
        sender += (' _' + '..' + status + '_ ');
    }
    sender += '\n';
    let modified_content = sender + prompt.content;
    
    return (
        <>
            <Markdown
                components={{
                    code({node, inline, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '');
                        return !inline && match ? (
                            <SyntaxHighlighter
                                {...props}
                                children={String(children).replace(/\n$/, '')}
                                style={coldarkDark}
                                language={match[1]}
                                PreTag="div"
                            />
                        ) : (
                            <code {...props} className={className}>
                                {children}
                            </code>
                        )
                    },
                    a({node, href, children, ...props}) {
                        return <a href={href} target="_blank">{children}</a>
                    }
                }}
                rehypePlugins={[remarkGfm]}
            >
                {modified_content}
            </Markdown>
            {prompt.details.sourceDocuments && prompt.details.sourceDocuments.length > 0 && (
                <p
                    className="flex float-left cursor-pointer"
                    onClick={() => GlobalEvents.dispatch('SHOW_SOURCE_DOCS', prompt.details)}
                >
                    [sources: <LinkIcon className={'h-4 w-4'} title={'sources'}/>]
                </p>
            )}
        </>
    );
};
