import {useEffect, useState} from "react";

export default () => {
    const [dotCount, setDotCount] = useState('.');

    useEffect(() => {
        const changeState = {
            '.'     : '..',
            '..'    : '...',
            '...'   : '.',
        };

        setInterval(() => setDotCount(count => changeState[count]), 1000);
    }, []);

    return (
        <div className="text-center align-middle h-auto w-auto justify-center m-auto">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mx-auto my-auto h-6 w-6 text-gray-400"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">Initializing{dotCount}</h3>
            <p className="mt-1 text-sm text-gray-500">Please wait for the conversation to be initialized.</p>
        </div>
    )
}
