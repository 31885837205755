import {useEffect, useState} from "react";
import {ToastContainer, Toast} from "react-bootstrap";
import GlobalEvents from "../events/GlobalEvents";

const ToastNotifications = () => {

    const [eventRegistered, setEventRegistered] = useState(false);
    const [notices, setNotices] = useState([]);

    useEffect(() => {
        if (!eventRegistered) {
            setEventRegistered(true);
            GlobalEvents.interpret('SHOW_TOAST_NOTICE', data => {
                setNotices(noticeList => ([
                    ...noticeList,
                    {...data, show: true}
                ]));
            });
        }
    }, [eventRegistered]);

    const expire = key => {
        setNotices(noticeList => {
            const nList = [...noticeList];
            if (nList[key]) {
                nList[key] = {
                    ...nList[key],
                    show: false
                }
            }
            return nList;
        });
    };

    return (
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 10000000000000000000 }}>
            {notices.map((notice, key) => (
                <Toast
                    key={key}
                    show={notice.show}
                    onClose={() => expire(key)}
                    onClick={() => expire(key)}
                    bg={notice.type ?? 'danger'}
                    delay={10000}
                    autohide
                >
                    <Toast.Body className={['primary', 'secondary', 'success', 'danger', 'dark'].indexOf(notice.type) >= 0 ? 'text-white' : ''}>
                        {notice.content}
                    </Toast.Body>
                </Toast>
            ))}
        </ToastContainer>
    );
};

export default ToastNotifications;