import {Fragment, useContext, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {
    ChatBubbleBottomCenterIcon,
    ChatBubbleBottomCenterTextIcon,
    DocumentIcon,
    HomeIcon,
    XMarkIcon,
    PencilIcon,
    SignalIcon,
    DocumentPlusIcon,
} from "@heroicons/react/24/outline";
import GlobalEvents from "../../../events/GlobalEvents";
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import {DELETE, GET, POST} from "../../../services/REST";
import {useLocation, useNavigate} from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import PersonaTeamIcon from  "../../../assets/persona-team-icon.svg";

export default () => {

    const ELLA_FEEDBACK_REPORTING = true;

    const V2Context = useContext(CompletionV2Context);
    const navigate = useNavigate();

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [conversationsList, setConversationsList] = useState([]);
    const [editingConvNameItem, setEditingConvNameItem] = useState(null);
    const [updatingName, setUpdatingName] = useState(false);

    const location = useLocation()
    const currentLocation = location?.pathname;

    useEffect(() => {
        GlobalEvents.interpret('OPEN_SIDENAV_BAR', ({state}) => setSidebarOpen(state));
    }, []);

    useEffect(() => {
        getConversationsList();
    }, []);

    const getConversationsList = () => {
        GET({
            url: 'chat-from-files/v2/list'
        })
            .then(response => {
                if (response.status) {
                    setConversationsList(response.content.conversations);
                }
            })
            .catch(() => null);
    }

    const deleteConversation = convId => {
        if (window.confirm('Are you sure you want to remove this conversation?')) {
            DELETE({
                url: 'chat-from-files/v2/conversation/'+convId,
            })
                .then(response => {
                    if (response.status) {
                        setConversationsList(conversationsList.filter(conv => conv.id !== convId));
                        if (convId === V2Context.conversationId) {
                            redirectTo('');
                        }
                    }
                })
                .catch(() => null);
        }
    }

    const redirectTo = convId => {
        V2Context.setConversationLoaded(false);
        navigate(convId ? '/conversation/'+convId : '/');
        setSidebarOpen(false);
    }

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button" className="-m-2.5 p-2.5"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-8 mt-3 w-auto"
                                            src="https://cognella.com/wp-content/uploads/2014/09/color650px.png"
                                            alt="Cognella"
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            { currentLocation !== '/' &&
                                                <li>
                                                    <ul role="list" className="-mx-2 space-y-1">
                                                        <li>
                                                            <a
                                                                onClick={() => redirectTo('')}
                                                                className={'bg-gray-50 text-cyan-800 hover:text-cyan-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer'}
                                                            >
                                                                <HomeIcon
                                                                    className={'text-cyan-900 h-6 w-6 shrink-0'}
                                                                    aria-hidden="true"
                                                                />
                                                                New Conversation
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>                                            
                                            }
                                            
                                            <li>
                                                <ul role="list" className="-mx-2 space-y-3">
                                                    {conversationsList.length > 0 && <li className={'p-2 pl-6 pr-6 font-semibold bg-slate-100'}>Conversations</li>}
                                                    {conversationsList.map((conversation, index) => (
                                                        <li key={index}>
                                                            <a
                                                                className={'hover:bg-gray-50 text-cyan-700 hover:text-cyan-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 cursor-pointer no-underline'+(V2Context.conversationId == conversation.id ? ' bg-gray-50 text-cyan-900' : '')}
                                                            >
                                                                {conversation.hasPersona && (
                                                                    ('team' === conversation.conversationType)
                                                                    ? <img className={'h-6 w-6 text-cyan-900'} src={PersonaTeamIcon} alt='Team' title='Team' />
                                                                    : (
                                                                        <>
                                                                            {conversation.contextFile
                                                                                ? <svg xmlns="http://www.w3.org/2000/svg" className={'text-cyan-900 h-6 w-6 shrink-0'} aria-hidden="true" title={'Entire File Context'} width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path><path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path><path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path></svg>
                                                                                : <svg xmlns="http://www.w3.org/2000/svg" className={'text-cyan-900 h-6 w-6 shrink-0'} aria-hidden="true" title={'Entire User Context'} width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path><path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path><path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path></svg>
                                                                            }
                                                                        </>
                                                                    )
                                                                )}
                                                                {!conversation.hasPersona && (
                                                                    <>
                                                                    {conversation.isVectorized
                                                                        ? (
                                                                            <>
                                                                                {conversation.contextFile
                                                                                    ? <DocumentPlusIcon className={'text-cyan-900 h-6 w-6 shrink-0'} aria-hidden="true" title={'Entire File Context'}/>
                                                                                    : <ChatBubbleBottomCenterTextIcon className={'text-cyan-900 h-6 w-6 shrink-0'} aria-hidden="true" title={'Entire User Context'}/>
                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {conversation.contextFile
                                                                                    ? <DocumentIcon className={'text-cyan-900 h-6 w-6 shrink-0'} aria-hidden="true" title={'Specific File Context'}/>
                                                                                    : <ChatBubbleBottomCenterIcon className={'text-cyan-900 h-6 w-6 shrink-0'} aria-hidden="true" title={'Specific User Context'}/>
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                    </>
                                                                )}
                                                                {editingConvNameItem != conversation.id
                                                                    ? (
                                                                        <>
                                                                            <span
                                                                                className="w-[80%] truncate"
                                                                                title={conversation.name ? conversation.name : conversation.id}
                                                                                onClick={() => redirectTo(conversation.id)}
                                                                            >
                                                                                <p className="font-semibold">{conversation.name ? conversation.name : conversation.id}</p>
                                                                                <p className="text-gray-500 text-xs">{new Date(conversation.created_at).toLocaleString()}</p>
                                                                            </span>
                                                                            <PencilIcon
                                                                                className="hidden group-hover:block text-gray-400 h-3 w-3 shrink-0 m-auto"
                                                                                title={'Edit'}
                                                                                onClick={() => setEditingConvNameItem(conversation.id)}
                                                                            />
                                                                            {conversation.id != V2Context.conversationId && (
                                                                                <XMarkIcon
                                                                                    className="hidden group-hover:block text-gray-400 h-3 w-3 shrink-0 m-auto"
                                                                                    title={'Remove'}
                                                                                    onClick={() => deleteConversation(conversation.id)}
                                                                                />
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="w-[80%]">
                                                                                <OutsideClickHandler
                                                                                    onOutsideClick={() => setEditingConvNameItem(null)}
                                                                                >
                                                                                    <input
                                                                                        type="text"
                                                                                        className="text-gray-500 text-xs"
                                                                                        defaultValue={conversation.name}
                                                                                        autoFocus={true}
                                                                                        onKeyDown={e => {
                                                                                            if (!e.shiftKey && (e.code === 'Enter')) {
                                                                                                e.preventDefault();
                                                                                                setUpdatingName(true);
                                                                                                POST({
                                                                                                    url: 'chat-from-files/v2/updateContextInstructions',
                                                                                                    content: {
                                                                                                        conversationId: editingConvNameItem,
                                                                                                        name: e.target.value
                                                                                                    }
                                                                                                })
                                                                                                    .then(response => {
                                                                                                        if (response.status) {
                                                                                                            setConversationsList(conversationsList.map(conv => {
                                                                                                                if (conv.id === conversation.id) {
                                                                                                                    conv.name = e.target.value;
                                                                                                                }
                                                                                                                return conv;
                                                                                                            }));
                                                                                                            setEditingConvNameItem(null);
                                                                                                        }
                                                                                                    })
                                                                                                    .catch(() => null)
                                                                                                    .finally(() => setUpdatingName(false));
                                                                                            }
                                                                                            if (e.code === 'Escape') {
                                                                                                setEditingConvNameItem(null);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </OutsideClickHandler>
                                                                            </div>
                                                                            {updatingName && <SignalIcon className={'text-gray-400 h-3 w-3 shrink-0 m-3 inline animate-spin'}/>}
                                                                        </>
                                                                    )
                                                                }
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};