import MessageCard from "./messagecard";
import {useContext, useEffect, useRef} from "react";
import {DataContext} from "../../../../contexts/DataProviderContext";
import {Row, Col, Spinner, Accordion} from "react-bootstrap";
import React from "react";

const Messages = props => {
    const Data = useContext(DataContext);

    const lastConvRef = useRef();

    useEffect(() => {
        lastConvRef.current.scrollIntoView({behavior: 'smooth'});
    }, [Data.currentConversation.chatHistory]);

    return (
        <div className={'conversations'}>
            <div className="chat-container">
                <Accordion flush>
                {Data.loadingConversations
                    ? <MessageCard content={<Row><Col sm={2}>Loading Context</Col><Col sm={1}><Spinner animation="border"/></Col></Row>}/>
                    : Data.currentConversation.chatHistory.length > 0
                        ? Data.currentConversation.chatHistory.map((chat, index) => (
                            <MessageCard
                                key={index}
                                index={index}
                                user={chat.role}
                                loading={chat.loading ?? false}
                                details={chat}
                                previous={Data.currentConversation.chatHistory[index-1]}
                                next={Data.currentConversation.chatHistory[index+1]}
                                content={chat.original_content}
                            />))
                        : null
                }
                </Accordion>
                <span ref={lastConvRef}/>
            </div>
        </div>
    );
};

export default Messages;