import {createContext, useContext, useEffect, useMemo, useState} from "react";
import GlobalEvents from "../../../../events/GlobalEvents";
import BoxBrowser from "./BoxBrowser";
import {DataContext} from "../../../../contexts/DataProviderContext";
import {Button, Modal} from "react-bootstrap";
import SectionSelector from "./SectionSelector";
import { Last } from "react-bootstrap/esm/PageItem";

export const BoxSearchContext = createContext();

export default () => {
    const Data = useContext(DataContext);
    const [show, setShow] = useState(false);

    const [selectedFile, setSelectedFile] = useState({});
    const [sectionsList, setSectionsList] = useState([]);
    const [mode, setMode] = useState('default');

    const showBackBtn = useMemo(() => (
        (['epub', 'pdf'].indexOf(selectedFile?.extension) >= 0)
    ), [selectedFile]);

    const showConfirmBtn = useMemo(() => (
        ((['epub', 'pdf'].indexOf(selectedFile?.extension) < 0) || sectionsList.length > 0)
    ), [selectedFile, sectionsList]);

    useEffect(() => {
        GlobalEvents.interpret('CONTEXT_SELECTOR_SHOW', (modeType) => {
            if (modeType !== undefined) {
                setMode(modeType); 
            }
            if (mode !== 'file') {
                setSelectedFile(null);
            }
            setShow(true);
        });
    }, []);

    const confirm = () => {
        const data = {...selectedFile};
        if (data.subsection && 'object' === typeof data.subsection) {
            data.subsection = Object.keys(data.subsection).filter(k => !!data.subsection[k]);
        }
        setShow(false);
        setTimeout(() => {
            Data.confirm(data, success => {
                success && GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Conversation initiated!', type: 'success'});
                setMode('default');
                setShow(!success);
            });
        }, 300);
    };

    return (
        <BoxSearchContext.Provider
            value={{
                selectedFile,
                setSelectedFile,
                sectionsList,
                setSectionsList,
                mode,
                setMode,
                confirm: () => showConfirmBtn && confirm()
            }}
        >
            <Modal
                show={show}
                fullscreen={'md-down'}
                size={'xl'}
                scrollable={true}
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Project File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedFile?.id && (['epub', 'pdf'].indexOf(selectedFile?.extension) >= 0)
                        ? <SectionSelector/>
                        : <span>
                            Choose a project file for initial conversation context. Supported file types are pdf, epub, and docx.
                            <BoxBrowser/>
                        </span>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {selectedFile?.id &&
                        <>
                        {showBackBtn && <Button variant={'secondary'} onClick={() => setSelectedFile(null)}>Back</Button>}
                        {showConfirmBtn && <Button variant={'success'} onClick={confirm}>Use</Button>}
                        </>
                    }
                    <Button variant={'danger'} onClick={() => setShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </BoxSearchContext.Provider>
    );
};