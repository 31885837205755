import {createContext, useEffect, useState} from "react";
import {GET} from "../services/REST";

export const PersonasContext = createContext(null);

export const PersonasProvider = ({children}) => {
    const [loaded, setLoaded] = useState(false);
    const [personas, setPersonas] = useState({});

    useEffect(() => {
        GET({
            url: 'chat-from-files/v2/personas'
        }).then(res => {
            if (res.status) {
                setPersonas(res.content);
            }
        }).catch(() => null)
        .finally(() => setLoaded(true));
    }, []);

    if (!loaded) return null;

    return (
        <PersonasContext.Provider
            value={personas}
        >
            {children}
        </PersonasContext.Provider>
    )
}

export default PersonasProvider;