import { createContext, useState, useEffect } from "react";
// import axios from "axios";
import { BACKEND_URL, GET } from "../services/REST";

// const HmacContext = createContext({ isValid: false });
const HmacContext = createContext();

const HmacContextProvider = ({ children }) => {
    const [isValid, setIsValid] = useState(false);

    return <HmacContext.Provider
        value={{isValid, setIsValid}}>

        {children}
    </HmacContext.Provider>;
}

export { HmacContext, HmacContextProvider };