import GlobalEvents from "../../../../events/GlobalEvents";
import {FormattedView} from "./FormattedView";

const User = ({record, index, previous, next}) => {
    const showNav = (event) => {
        event.preventDefault();
        GlobalEvents.dispatch('SHOW_MESSAGE_CONTEXT_MENU', {
            coordinates: {
                x: event.pageX,
                y: event.pageY,
            },
            record,
            index,
            previous,
            next
        });
    };

    return (
        <div className="col-start-2 col-end-13 p-3 rounded-lg position-relative">
            <div className="flex items-center justify-start flex-row-reverse">
                <div className="absolute top-4 right-0 flex items-center justify-center h-10 w-10 rounded-full bg-stone-100 flex-shrink-0">U</div>
                <div
                    className="relative mr-10 text-sm bg-cyan-700/80 hover:bg-cyan-700/90 text-white py-2 px-4 shadow rounded-xl"
                    onContextMenu={showNav}
                >
                    {record?.content ? <FormattedView details={record} content={record.content} user={record.role} created_at={record.created_at}/> : ''}
                </div>
            </div>
        </div>
    );
};

export default User;