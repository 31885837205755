import {createContext, useContext, useEffect, useState} from "react";
import {GET, POST} from "../services/REST";
import GlobalEvents from "../events/GlobalEvents";
import {CompletionV2Context} from "./CompletionV2Context";
import {PersonasContext} from "./PersonasContext";
export const DataContext = createContext(null);

const sampleInstructionsWithoutContext = 'You are Ella, the Cognella AI Assistant that answers the user\'s questions.\n' +
    'If the user asks for a table, always format it as a markdown table.';

const openaiInstructions = 'You are Ella, the Cognella AI Assistant that answers questions based on the given context here: ' + 
            '\n{{context}}' +
            '\n' + 
            '\nPlease answer the user\'s questions about the context using the following instructions: ' + 
            '\n1. All of your answers should be based on the given context within this conversation with as little information outside of the context as possible.' +
            '\n2. If you are unable to find the answer after searching in the given context, add this text as an italics paragraph above your response: \'*This response contains some information from outside of your selected context:* \'. ' + 
            '\n3. If the user asks for a table, always format it as a markdown table.'


const anthropicInstructions = 'You are Ella, the Cognella AI Assistant that answers questions based on the given context here: ' + 
            '\n{{context}}' +
            '\n' + 
            '\nPlease answer the user\'s questions about the context using the following instructions: ' + 
            '\n1. All of your answers should be based on the given context within this conversation with as little information outside of the context as possible.' +
            '\n2. If your response primarily contains answers outside of the given context, add this text as an italics paragraph above your response: \'*This response primarily contains information from outside of your selected context:* \'. ' + 
            '\n3. If the user asks for a table, always format it as a markdown table.'

const DataProviderContext = ({children}) => {

    const V2Context = useContext(CompletionV2Context);
    const AsstConfig = useContext(PersonasContext);

    const [loading, setLoading] = useState(false);
    const [loadingConversations, setLoadingConversations] = useState(false);
    const [loadingPrompt, setLoadingPrompt] = useState(false);

    const [SKU, setSKU] = useState(null);
    const [filesList, setFilesList] = useState([]);
    const [sectionsList, setSectionsList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const [currentConversationSKU, setCurrentConversationSKU] = useState('');
    const [currentConversationContext, setCurrentConversationContext] = useState('');
    const [currentConversationDocument, setCurrentConversationDocument] = useState(null);
    const [conversationHistory, setConversationHistory] = useState([]);
    const [contentTrimmed, setContentTrimmed] = useState(false);
    const [instructions, setInstructions] = useState('');
    const [initialContext, setInitialContext] = useState('');
    const [user, setUser] = useState(null);

    const [settings, setSettings] = useState({
        loaded: false,
        model: null,
        availableModels: {},
    });

    const [tokenUsage, setTokenUsage] = useState({
        modelDetails: null,
        usage: null // object with token_count
    });    
    
    let availablePersonas = [];
    let availableTeams = [];
    if (AsstConfig?.personas) {
        availablePersonas = AsstConfig.personas;
    }
    if (AsstConfig?.teams) {
        availableTeams = AsstConfig.teams;
    }

    const [personas, setPersonas] = useState(availablePersonas)
    const [teams, setTeams] = useState(availableTeams)

    const personaRunStatusesForWait = ['queued', 'in_progress']

    useEffect(() => {
        GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'retrieve-engines', show: true, message: 'Retrieving Personas', description: 'Loading Ella Personas for you...'});
        GET({
            url: 'gpt-engines/get-engines',
            token: window.user
        }).then(response => {
            if (response.status) {
                let savedEngine = localStorage.getItem('engine');
                let defaultEngine = response.content.defaultEngine;

                const allModels = Object.values(response.content.engines).map(p => p.models).flat();

                if (allModels.length > 0) {
                    if (allModels.find(model => ((model.name === savedEngine) && model.isAvailable))) {
                        defaultEngine = savedEngine;
                    }

                    setSettings({
                        loaded: true,
                        model: defaultEngine,
                        availableModels: response.content.engines
                    });
                    GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'retrieve-engines', show: false});
                } else {
                    GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'No engines found!'});
                    GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'retrieve-engines', show: true, message: 'Error', description: response.message, showReturn: true});
                }
            }
        }).catch(error => {
            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to fetch engines!'});
            GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'retrieve-engines', show: true, message: 'Error', description: error.message, showReturn: true});
        });
    }, []);

    useEffect(() => {
        if (!SKU) {
            setFilesList([]);
            setSelectedFile(null);
            return;
        }

        setLoading(true);
        GET({
            url: 'chat-from-files/search?sku='+SKU,
        })
            .then(response => {
                if (response.status) {
                    if (response.content.files.length > 0) {
                        setFilesList(response.content.files.filter(file => (['doc', 'docx', 'pdf', 'epub'].indexOf(file.extension) >= 0)));
                    } else {
                        setFilesList([{id: null, name: 'No files found!'}])
                    }
                }
            })
            .catch(error => {
                GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to fetch files!'});
            })
            .finally(() => {
                setLoading(false);
            });
    }, [SKU]);

    useEffect(() => {
        setSelectedFile(null);
    }, [filesList]);

    const getSubsection = file => {
        if (!file?.id || !file?.subsection || file?.subsection.length <= 0) {
            return null;
        }

        if ('epub' === file.extension && file.subsection.length > 0) {
            return file.subsection;
        }

        if ('pdf' === file?.extension && file.subsection) {
            const ranges = {};

            file.subsection.split(',').forEach(i => {
                if (i.indexOf('-') > 0) {
                    const iS = i.split('-');

                    let i1 = Number(iS[0]);
                    let i2 = Number(iS[1]);

                    if (i1 > i2) {
                        i1 = Number(iS[1]);
                        i2 = Number(iS[0]);
                    }

                    for (let a=0;a<=i2-i1;a++) {
                        ranges[i1+a] = true;
                    }
                } else {
                    ranges[i] = true;
                }
            });

            return Object.keys(ranges);
        }

        return null;
    };

    const getInitialContext = () => {
        const contextRecord = conversationHistory.find(record => record?.metadata?.context);
        return contextRecord && contextRecord.content ? contextRecord.content.replace('Given context: ', '') : '';
    };

    const getInstructions = () => {
        const instRecord = conversationHistory.filter(record => record?.metadata?.instructions);
        if (instRecord.length > 0) {
            return instRecord.map(i => i.content).join('{{context}}');
        }

        return Object.values(settings.availableModels).map(p => p.models).flat().find(model => model.name === settings.model)?.instructions;
    };

    return (
        <DataContext.Provider value={{
            loading: loading,
            loadingConversations: loadingConversations,
            setLoadingConversations,
            loadingPrompt: loadingPrompt,
            // populateUser: (user) => {
            //     setUser(user);
            // },
            sku: {
                get: SKU,
                set: setSKU
            },
            filesList: {
                get: filesList,
                set: setFilesList
            },
            sectionsList: {
                get: sectionsList,
                set: setSectionsList
            },
            selectedFile: {
                get: selectedFile,
                set: setSelectedFile
            },
            user: {
                get: user,
                set: setUser
            },
            currentConversation: {
                sku: currentConversationSKU,
                document: currentConversationDocument,
                setDocument: setCurrentConversationDocument,
                context: currentConversationContext,
                chatHistory: conversationHistory,
                setChatHistory: setConversationHistory,
                contentTrimmed: contentTrimmed,
                tokenUsage: tokenUsage,
            },
            settings: settings,
            setEngine: engine => {
                setSettings(s => {
                    const newSettings = {...s};
                    newSettings.model = engine;
                    return newSettings;
                });
            },
            instructions,
            setInstructions,
            initialContext,
            setInitialContext,
            personas,
            setPersonas,
            teams, setTeams,
            personaRunStatusesForWait,
            confirmSimpleConversation: (callback = null) => {
                setConversationHistory([]);
                setCurrentConversationContext('');
                setLoadingConversations(true);
                setContentTrimmed(false);
                setCurrentConversationDocument(null);
                setTokenUsage({modelDetails: null, usage: null});
                POST({
                    url: 'chat-from-files/initiate',
                    content: {
                        model: settings.model,
                        instructions: sampleInstructionsWithoutContext
                    },
                })
                    .then(response => {
                        if (response.status) {
                            setCurrentConversationContext(response.content.context);
                            setConversationHistory(response.content.conversations);
                            setTokenUsage(u => ({...u, modelDetails: response.content.modelDetails}));
                        } else {
                            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Unable to initiate conversation!'});
                        }
                        callback && setTimeout(() => callback(response.status), 200);
                    })
                    .catch(error => {
                        callback && callback(false);
                        GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to initiate conversation!'});
                    })
                    .finally(() => {
                        setLoadingConversations(false);
                    });
            },
            confirmSimpleVectorConversation: (callback = null) => {
                setConversationHistory([]);
                setCurrentConversationContext('');
                setLoadingConversations(true);
                setCurrentConversationDocument(null);
                setTokenUsage({modelDetails: null, usage: null});

                GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'initiate-conversation', show: true, message: 'Initiating', description: 'Booting up new conversation for you...'});
                POST({
                    url: 'chat-from-files/v2/initiate',
                    content: {
                        instructions: sampleInstructionsWithoutContext,
                        isVectorized: V2Context.vectorMode,
                        assistant: V2Context.currentPersona?.id !== 'default' ? V2Context.currentPersona?.id : null
                    }
                })
                    .then(response => {
                        if (response.status) {
                            V2Context.setConversationId(response.content.conversationId);
                            V2Context.setConversationMetadata(response.content.conversationMetadata);
                            setConversationHistory(response.content.conversations);
                            GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {show: false});
                            GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'initiate-conversation', show: false});
                        } else {
                            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Unable to initiate conversation!'});
                            GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'initiate-conversation', show: true, message: 'Error', description: response.message, showReturn: true});
                        }
                        callback && setTimeout(() => callback(response.status, response.content.conversationId), 200);
                    })
                    .catch(error => {
                        callback && callback(false);
                        GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to initiate conversation!'});
                        GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'initiate-conversation', show: true, message: 'Error', description: error.message, showReturn: true});
                    })
                    .finally(() => {
                        setLoadingConversations(false);
                    });
            },
            confirm: (file, callback = null) => {
                setConversationHistory([]);
                setLoadingConversations(true);
                setContentTrimmed(false);
                setTokenUsage(u => ({...u, usage: null}));

                const providerValue = Object.values(settings.availableModels).find(m => {return m.models.find(v => {return v.name === settings.model})}),
                providerKey = Object.keys(settings.availableModels).find(k => {return settings.availableModels[k] === providerValue}),
                modelKey = settings.availableModels[providerKey].models.findIndex(m => {return m.name === settings.model});
                POST({
                    url: 'chat-from-files/initiate',
                    content: {
                        fileId: file ? file.id : null,
                        subsection: file ? getSubsection(file) : null,
                        model: settings.model,
                        instructions: file ? settings.availableModels[providerKey].models[modelKey].instructions : sampleInstructionsWithoutContext
                    },
                })
                    .then(response => {
                        if (response.status) {
                            setCurrentConversationDocument(file);
                            setCurrentConversationContext(response.content.context);
                            setContentTrimmed(response.content.contentTrimmed);
                            setConversationHistory(response.content.conversations);
                            setTokenUsage(u => ({
                                ...u,
                                usage: response.content.usage,
                                modelDetails: response.content.modelDetails
                            }));

                            if ('pdf' === file?.extension && response.content?.croppedAtPage) {
                                GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: `The page range${file.subsection ? ' of '+file.subsection : ''} is too large for this file. The context selected was cropped in the middle at page ${response.content.croppedAtPage}.`});
                            }
                        } else {
                            setCurrentConversationDocument(null);
                            setCurrentConversationContext('');
                            setContentTrimmed(false);
                            setConversationHistory([]);
                            setTokenUsage({
                                modelDetails: null,
                                usage: null
                            });
                            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Unable to initiate conversation!'});
                        }
                        callback && setTimeout(() => callback(response.status), 200);
                    })
                    .catch(error => {
                        callback && callback(false);
                        GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to initiate conversation!'});
                    })
                    .finally(() => {
                        setLoadingConversations(false);
                    });
            },
            confirmVector: (file, callback = null) => {
                setConversationHistory([]);
                setCurrentConversationContext('');
                setLoadingConversations(true);
                setContentTrimmed(false);
                setTokenUsage(u => ({...u, usage: null}));

                let assistantId = V2Context.currentPersona?.id !== 'default' ? V2Context.currentPersona?.id : null;
                if (!assistantId && V2Context.currentMultiPersonas.length > 0) {
                    assistantId = 'multiple';
                }
                if (V2Context.currentPersona?.type === 'team') {
                    assistantId = 'team';
                }

                const providerValue = Object.values(settings.availableModels).find(m => {return m.models.find(v => {return v.name === settings.model})}),
                    providerKey = Object.keys(settings.availableModels).find(k => {return settings.availableModels[k] === providerValue}),
                    modelKey = settings.availableModels[providerKey].models.findIndex(m => {return m.name === settings.model});

                let name = null;
                let instructions = file ? settings.availableModels[providerKey].models[modelKey].instructions : sampleInstructionsWithoutContext;
                const supervisorAsstId = V2Context.currentPersona?.supervisorAssistantId ?? null;
                const tools = V2Context.currentPersona?.tools ?? [];

                let multiPersonaIds = []
                if ('multiple' === assistantId) {
                    multiPersonaIds = V2Context.currentMultiPersonas;
                } else if ('team' === assistantId) {
                    name = V2Context.currentPersona.name;
                    multiPersonaIds = V2Context.currentPersona.assistants;
                    if (V2Context.currentPersona?.instructions) {
                        instructions = V2Context.currentPersona.instructions;
                    }
                }

                GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'initiate-conversation', show: true, message: 'Initiating', description: 'Booting up new conversation for you...'});
                POST({
                    url: 'chat-from-files/v2/initiate',
                    content: {
                        name: name,
                        fileIds: file?.files?.map(f => f.id),
                        subsection: file?.files[0] ? getSubsection(file.files[0]) : null,
                        actualSubsection: (file?.files && file.files[0]?.subsection) ?? null,
                        instructions: instructions,
                        isVectorized: V2Context.vectorMode,
                        assistant: assistantId,
                        assistant_ids: multiPersonaIds,
                        supervisor_assistant_id: supervisorAsstId,
                        tools: tools
                    }
                })
                    .then(response => {
                        if (response.status) {
                            const {text, ...rFile} = response.content?.initialContext;
                            setCurrentConversationDocument(rFile);
                            V2Context.setConversationId(response.content.conversationId);
                            V2Context.setConversationMetadata(response.content.conversationMetadata);
                            rFile.files = rFile?.files?.map(file => {
                                if (file.id === response.content?.conversationMetadata?.fileId) {
                                    file.metadata = response.content?.conversationMetadata;
                                }
                                return file;
                            });
                            V2Context.setCurrentFile(rFile);
                            setConversationHistory(response.content.conversations);
                            setInstructions(response.content.instructions);
                            setInitialContext(text ?? '');

                            if ('pdf' === rFile?.extension && response.content.conversationMetadata?.croppedAtPage) {
                                GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: `The page range${rFile.actualSubsection ? ' of '+rFile.actualSubsection : ''} is too large for this file. The context selected was cropped in the middle at page ${response.content.conversationMetadata?.croppedAtPage}.`});
                            }
                            GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'initiate-conversation', show: false});
                        } else {
                            setCurrentConversationDocument(null);
                            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Unable to initiate conversation!'});
                            GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'initiate-conversation', show: true, message: 'Error', description: response.message, showReturn: true});
                        }
                        callback && setTimeout(() => callback(response.status, response.status ? response.content.conversationId : null), 200);
                    })
                    .catch(error => {
                        callback && callback(false);
                        GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to initiate conversation!'});
                        GlobalEvents.dispatch('SHOW_LOADING_CANVAS', {type: 'initiate-conversation', show: true, message: 'Error', description: error.message, showReturn: true});
                    });
            },
            confirmSettings: (payload, callback = null) => {
                const input = {};

                if (payload.model) {
                    input.model = payload.model;
                } else {
                    input.model = settings.model;
                }

                if (payload.hasOwnProperty('instructions')) {
                    input.availableModels = {...settings.availableModels};
                    const providerValue = Object.values(settings.availableModels).find(m => {return m.models.find(v => (v.name === input.model))});
                    const providerKey = Object.keys(settings.availableModels).find(k => {return settings.availableModels[k] === providerValue});
                    const modelKey = settings.availableModels[providerKey].models.findIndex(m => {return m.name === input.model});
                    input.availableModels[providerKey].models[modelKey].instructions = payload.instructions;
                    input.instructions = payload.instructions;
                } else {
                    input.instructions = getInstructions();
                }

                if (payload.hasOwnProperty('initialContext')) {
                    input.initialContext = payload.initialContext;
                } else {
                    input.initialContext = getInitialContext();
                }

                POST({
                    url: 'chat-from-files/updateContextInstructions',
                    content: {
                        model: input.model,
                        instructions: input.instructions ?? sampleInstructionsWithoutContext,
                        initialContext: input.initialContext
                    },
                })
                    .then(res => {
                        if (res.status) {
                            setConversationHistory(history => {
                                const newHistory = history.filter(h => (!h?.metadata?.context && !h?.metadata?.instructions));
                                return res.content.conversations.concat(newHistory);
                            });
                            setCurrentConversationContext(res.content.context);
                            setContentTrimmed(res.content.contentTrimmed);

                            localStorage.setItem('engine', input.model);
                            setSettings({
                                ...settings,
                                ...input
                            });

                            setTokenUsage(u => ({
                                ...u,
                                usage: res.content?.usage,
                                modelDetails: res.content.modelDetails
                            }));
                        }

                        callback && callback(res.status);
                        if (!res.status) {
                            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: res.message ?? 'Unable to update details!'});
                        }
                    })
                    .catch(err => {
                        callback && callback(false);
                        GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: err.message ?? 'Unable to update details!'});
                    });
            },

            //Used for Conversation V2
            confirmVectorSettings: (payload, callback = null) => {
                if (!V2Context.conversationId) {
                    return;
                }

                const input = {};

                if (payload.model) {
                    input.model = payload.model;
                } else {
                    input.model = settings.model;
                }

                if (payload.hasOwnProperty('instructions')) {
                    input.instructions = payload.instructions;
                }

                if (payload.hasOwnProperty('initialContext')) {
                    input.initialContext = payload.initialContext;
                }

                POST({
                    url: 'chat-from-files/v2/updateContextInstructions',
                    content: {
                        ...input,
                        conversationId: V2Context.conversationId,
                    }
                })
                    .then(res => {
                        if (res.status) {
                            localStorage.setItem('engine', input.model);
                            setSettings({
                                ...settings,
                                ...input
                            });
                            //TODO: change this to account for multiple files support
                            V2Context.setCurrentFile(f => ({...f, metadata: res.content?.conversationMetadata}));
                        }
                        callback && callback(res.status);
                        if (!res.status) {
                            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: res.message ?? 'Unable to update details!'});
                        }
                    })
                    .catch(err => {
                        callback && callback(false);
                        GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: err.message ?? 'Unable to update details!'});
                    });
            },

            //Used for Conversation V1
            sendPrompt: (prompt, callback = null) => {
                if (prompt) {

                    let tokenCount = 0;
                    conversationHistory.forEach(chat => {
                        tokenCount += chat?.metadata?.tokenCount ?? 0;
                    });

                    if (tokenCount > (tokenUsage?.modelDetails?.maxTokenLimitForModel ?? 0) * 0.98) { // 2% margin provided for calculation errors
                        callback && callback(false);
                        GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Conversation capacity has been reached. Please start a new conversation or remove some prompts to continue!', type: 'warning'});
                        return;
                    }

                    setLoadingPrompt(true);
                    const existingConvHist = [...conversationHistory];

                    setConversationHistory([
                        ...conversationHistory,
                        {
                            role: 'user',
                            content: prompt,
                            original_content: prompt,
                            timestamp: Date.now()
                        },
                        {
                            role: 'assistant',
                            loading: true,
                            exclude: true
                        }
                    ]);

                    POST({
                        url: 'chat-from-files/prompt',
                        content: {
                            conversations: conversationHistory.filter(c => !c.exclude),
                            prompt: prompt,
                            model: settings.model,
                            instructions: settings.instructions
                        },
                    })
                        .then(response => {
                            if (response.status){
                                setConversationHistory(response.content.conversations);
                                setTokenUsage(u => ({...u, usage: response.content.usage}));
                                response.content.maxTokenReached && GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'This conversation has reached capacity. The last answer is most likely incomplete. Please remove some responses from the conversation or start again.', type: 'warning'});
                            } else {
                                setConversationHistory(existingConvHist);
                                GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Unable to query prompt!'});
                            }
                            setTimeout(() => callback(response.status), 200);
                        })
                        .catch(error => {
                            setConversationHistory(existingConvHist);
                            callback && callback(false);

                            // gpt 4 hits TPM limit quickly, we need to explain to the user better than OpenAI's default error message
                            if (error.message.includes("messaging volume has exceeded")) {
                                let message = "Ella has exceeded the messaging volume limit for GPT 4 and is deeply sorry--shocked, even--that such low limits exist. Please try your prompt again in a minute.";
                                const fun = Math.floor(Math.random() * 3);
                                switch (fun) {
                                    case 1: message = "Ella has exceeded the messaging volume limit for GPT 4. She sends her love, her apologies, and a small box of assorted cookies. Please try your prompt again in a minute."; break;
                                    case 2: message = "Ella has reached the zenith of GPT-4's messaging allowance. She conveys her refined regrets and suggests a brief intermission before sending your prompt again."; break;
                                }
                                GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: message});
                            }
                            else {
                                GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to query prompt!'});
                            }
                        })
                        .finally(() => {
                            setLoadingPrompt(false);
                        });
                } 
            },

            //Used for Conversation V2
            sendVectorPrompt: (prompt, callback = null) => {
                if (V2Context.conversationId && prompt) {
                    setLoadingPrompt(true);
                    const existingConvHist = [...conversationHistory];

                    setConversationHistory([
                        ...conversationHistory,
                        {
                            role: 'user',
                            content: prompt,
                            original_content: prompt,
                            timestamp: Date.now()
                        },
                        {
                            role: 'assistant',
                            loading: true,
                            exclude: true
                        }
                    ]);

                    const content = {
                        conversationId: V2Context.conversationId,
                        prompt: prompt,
                    }

                    // Send this value so that backend decides if to run the process in Sync/Async
                    if (V2Context?.currentPersona?.isAsync) {
                        content.isAsync = true
                    }

                    POST({
                        url: 'chat-from-files/v2/prompt',
                        content: content
                    })
                        .then(response => {
                            if (response.status){
                                setConversationHistory(response.content.conversations);
                                // response.content.cost && GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: response.content.cost});
                            } else {
                                setConversationHistory(existingConvHist);
                                GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Unable to query prompt!'});
                            }
                            setTimeout(() => callback({status: response.status, run_status: response.content?.response?.run_status}), 200);
                        })
                        .catch(error => {
                            setConversationHistory(existingConvHist);
                            callback && callback({status: false, run_status: null});
                            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to query prompt!'});
                        })
                        .finally(() => {
                            setLoadingPrompt(false);
                        });
                }
            },
            pingAssistant: (callback = null) => {
                if (V2Context.conversationId) {
                    POST({
                        url: 'chat-from-files/v2/ping-assistant',
                        content: {
                            conversationId: V2Context.conversationId
                        }
                    })
                        .then(response => {
                            if (response.status && parseInt(response.content?.response?.convId) === parseInt(V2Context.conversationId) && window.location.href.indexOf(response.content?.response?.convId) > -1) {
                                setConversationHistory(response.content.conversations);
                                callback && callback({status: response.status, run_status: response.content?.response?.run_status, run_conv_id: response.content?.response?.convId});
                            } else {
                                callback && callback({status: false, run_status: null, run_conv_id: null});
                            }
                        })
                        .catch(error => {
                            callback && callback({status: false, run_status: null, run_conv_id: null});
                            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to get update from persona!'});
                        })
                }
            },
            removePrompt: index => {
                const replicaArray = [...conversationHistory];
                if ((index < 0) || (index >= replicaArray.length)) {
                    return;
                }

                replicaArray.splice(index, 2);
                setConversationHistory(replicaArray);
            },
            clearConversation: () => {
                setConversationHistory(history => history.filter(h => (['user', 'assistant'].indexOf(h.role) < 0)));
            },
            getInitialContext,
            getInstructions,
        }}>
            {children}
        </DataContext.Provider>
    );
};

export default DataProviderContext;
