import {useContext, useEffect, useRef, useState} from "react";
import {GET} from "../../../../services/REST";
import {BoxSearchContext} from "./index";
import GlobalEvents from "../../../../events/GlobalEvents";

export default () => {
    const Data = useContext(BoxSearchContext);
    const ref = useRef();

    useEffect(() => {
        GET({
            url: 'chat-from-files/box-setup'
        })
            .then(response => {
                if (response.status) {
                    if (response.content.token && response.content.folderId) {
                        ref.current = new window.Box.ContentExplorer();

                        ref.current.addListener('select', (event) => {
                            if (event[0] && event[0].type === 'file') {
                                let fileInfo = {
                                    id: event[0].id,
                                    name: event[0].name,
                                    extension: event[0]?.extension,
                                }, path = [];
                                Data.setSectionsList([]);
                                Data.setSelectedFile(fileInfo);
                                localStorage.setItem('file', JSON.stringify(fileInfo));
                                if (event[0]?.path_collection?.entries) {
                                    event[0].path_collection.entries.forEach((entry) => {
                                        // const regex = /^\d{5}-\d[A-Za-z]$/;//sku regex
                                        if (entry.id > 0) {
                                            path.push( {
                                                id: entry.id,
                                                name: entry.name,
                                            })
                                        }
                                    })
                                }
                                if (path) {
                                    localStorage.setItem('path', JSON.stringify(path));
                                }
                            }
                        });
                        let targetFolderId = response.content.folderId;
                        if (Data.mode === 'file' && localStorage.getItem('file')) {
                            let fileInfo = JSON.parse(localStorage.getItem('file'));
                            Data.setSectionsList([]);
                            Data.setSelectedFile(fileInfo);
                        } else {
                            if (/^\d+$/.test(Data.mode)) {
                                targetFolderId = Data.mode;
                                Data.setSelectedFile({});
                                Data.setSectionsList([]);
                            }
                         
                            ref.current.show(targetFolderId, response.content.token, {
                                canDelete: false,
                                canPreview: false,
                                canDownload: false,
                                canShare: false,
                                canRename: false,
                                canUpload: false,
                                container: '.box-container',
                                canCreateNewFolder: false,
                                canSetShareAccess: false,
                                logoUrl: 'https://cognella.com/wp-content/uploads/2014/09/color650px.png',
                                size: 'large',
                                // initialPageSize: 25,
                            responseInterceptor: (response) => {
                                if (response.data && response.data.item_collection && response.data.item_collection.entries) {
                                    let nonEmptyItems = response.data.item_collection.entries.filter((x) => x.size > 0);
                                    if (nonEmptyItems.length > 0) {
                                        response.data.item_collection.entries = nonEmptyItems;
                                    }
                                }
                                return response;
                            },
                        });
                        }                        
                    }
                }
            })
            .catch(error => {
                GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to initiate Box!'});
            })
            .finally(() => null);
    }, []);

    return (
        <div
            ref={ref}
            className={'box-container'}
        />
    );
};