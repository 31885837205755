import GlobalEvents from "../../../../events/GlobalEvents";
import {DataContext} from "../../../../contexts/DataProviderContext";
import {CompletionV2Context} from "../../../../contexts/CompletionV2Context";
import {useContext, useMemo} from "react";
import {FormattedView} from "./FormattedView";
import LoadingDots from "../LoadingDots";
import PersonaTeamIcon from  "../../../../assets/persona-team-icon.svg";

const Assistant = ({record, index, previous, next}) => {

    const showNav = (event) => {
        event.preventDefault();
        GlobalEvents.dispatch('SHOW_MESSAGE_CONTEXT_MENU', {
            coordinates: {
                x: event.pageX,
                y: event.pageY,
            },
            record,
            index,
            previous,
            next
        });
    };
    const Data = useContext(DataContext);
    const personaName = useMemo(() => {
        if ('multiple' === record.personaId)
            return 'Multiple';
        return Data?.personas?.find(x => x.id === record.personaId)?.name;
    }, []);
    const V2Context = useContext(CompletionV2Context);
    
    return (
        <div className="col-start-1 col-end-12 p-3 rounded-lg">
            <div className="relative flex flex-row items-center align-top">
                <div className="absolute left-0 top-0 flex items-center justify-center h-10 w-10 rounded-full flex-shrink-0">
                    {record?.personaId && (record?.personaId === 'team' ? <img className={'h-6 w-6 text-cyan-900'} src={PersonaTeamIcon} alt='Team' title='Team' /> : (<figure className="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" style={{display:'block', margin:'auto'}} className="pointer-events-none cognella-dark" aria-hidden="true" width="2em" height="2em" fill="currentColor" viewBox="0 0 24 24"><path d="M11 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path><path fillRule="evenodd" d="M12 1a1 1 0 0 1 1 1v.5h4a3 3 0 0 1 3 3V9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V5.5a3 3 0 0 1 3-3h4V2a1 1 0 0 1 1-1ZM7 4.5h10a1 1 0 0 1 1 1V9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V5.5a1 1 0 0 1 1-1Z" clipRule="evenodd"></path><path d="M6 21c0-.974.551-1.95 1.632-2.722C8.71 17.508 10.252 17 12 17c1.749 0 3.29.508 4.369 1.278C17.449 19.05 18 20.026 18 21a1 1 0 1 0 2 0c0-1.788-1.016-3.311-2.469-4.35-1.455-1.038-3.414-1.65-5.53-1.65-2.118 0-4.077.611-5.532 1.65C5.016 17.69 4 19.214 4 21a1 1 0 1 0 2 0Z"></path></svg>
                        </figure>))}
                    {!record?.personaId && <img src='/cognella_icon.jpg'/>}
                </div>
                <div
                    className="relative ml-12 text-sm py-2 px-4 shadow rounded-xl"
                    onContextMenu={showNav}
                >
                    {record.hasOwnProperty('content') ? (
                        <FormattedView details={record} content={record.content ?? ''} user={record.role} persona={personaName} created_at={record.created_at}/>
                    ) : (
                        <>
                            <FormattedView details={record} content="" user={record.role} persona={personaName} status={V2Context.assistantRunStatus}/>
                            <LoadingDots/>
                        </>
                    )}
          
                </div>
            </div>
        </div>
    );
};

export default Assistant;