import {createContext, useState, useContext, useEffect} from "react";
import {GET} from "../services/REST";

export const CompletionV2Context = createContext(null);

const CompletionV2Provider = ({children}) => {

    const [currentPrompt, setCurrentPrompt] = useState('');
    const [currentFile, setCurrentFile] = useState({files: []});
    const [currentModel, setCurrentModel] = useState(null);
    const [vectorMode, setVectorMode] = useState(false);
    const [currentPersona, setCurrentPersona] = useState(null);
    const [currentMultiPersonas, setCurrentMultiPersonas] = useState([]);
    // const [currentTeamPersona, setCurrentTeamPersona] = useState(null);
    const [conversationLoaded, setConversationLoaded] = useState(false);
    const [conversationId, setConversationId] = useState(null);
    const [conversationMetadata, setConversationMetadata] = useState(null);
    const [assistantRunStatus, setAssistantRunStatus] = useState('');

    return (
        <CompletionV2Context.Provider
            value={{
                currentPrompt,
                setCurrentPrompt,
                currentFile,
                setCurrentFile,
                currentModel,
                setCurrentModel,
                currentPersona,
                setCurrentPersona,
                currentMultiPersonas, setCurrentMultiPersonas,
                // currentTeamPersona, setCurrentTeamPersona,
                vectorMode,
                setVectorMode,
                conversationId,
                setConversationId,
                conversationMetadata, setConversationMetadata,
                conversationLoaded,
                setConversationLoaded,
                assistantRunStatus,
                setAssistantRunStatus
            }}
        >
            {children}
        </CompletionV2Context.Provider>
    )
};

export default CompletionV2Provider;