import "./index.css";
import Messages from "./components/messages";
import {default as ContextChangerModal} from "./components/ContextHandlerFromBoxDirectory";
import TokenFullnessProgressbar from "./components/TokenFullnessProgressbar";
import ContextHeader from "./components/ContextHeader";
import Settings from "./components/Settings";
import {InputGroup, Form} from "react-bootstrap";
import React, {useContext, useState} from "react";
import {Send} from "react-bootstrap-icons";
import {DataContext} from "../../contexts/DataProviderContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import ToastNotifications from "../../components/ToastNotifications";

const CompletionPage = () => {
    const Data = useContext(DataContext);
    const [prompt, setPrompt] = useState('');

    const submit = event => {
        event.preventDefault();
        if (prompt) {
            const promptBak = prompt;
            setPrompt('');
            Data.sendPrompt(prompt, success => {
                if (!success) {
                    setPrompt(promptBak);
                }
            });
        }
    };

    const getLastPrompt = () => {
        let lastPrompt = '';

        Data.currentConversation.chatHistory.forEach(chat => {
            if (chat.role === 'user') {
                lastPrompt = chat.content;
            }
        });

        return lastPrompt;
    };

    return (
        <div style={{height: '100%'}}>
            <div className={'top-context-header'}>
                <ContextHeader/>
            </div>
            <div className={'conversation-container'}>
                <Messages/>
            </div>
            <div className={'bottom-fixed'}>
                {Data.currentConversation.chatHistory.length > 0 &&
                <Form className={'prompt-form mb-3'} onSubmit={submit}>
                    <InputGroup>
                        <Form.Control
                            className={'enter-text'}
                            value={prompt}
                            onChange={e => setPrompt(e.target.value)}
                            onKeyUp={e => {
                                if (!prompt && (e.code === 'ArrowUp')) {
                                    setPrompt(getLastPrompt())
                                }
                            }}
                            placeholder="Ask anything about this content..."
                        />
                        <InputGroup.Text className={'send-icon'}><Send/></InputGroup.Text>
                    </InputGroup>
                </Form>
                }
                <TokenFullnessProgressbar className={'progessbar-container'} />
            </div>

            <ContextChangerModal/>
            <Settings/>
            <ToastNotifications/>
        </div>
    );
};

export default CompletionPage;