import {useContext, useEffect, useRef, useState} from "react";
import {BoxSearchContext} from "./index";
import {POST} from "../../../../services/REST";
import {Form, Container, Row, Spinner} from "react-bootstrap";
import GlobalEvents from "../../../../events/GlobalEvents";

export default () => {
    const Data = useContext(BoxSearchContext);
    const pdfInputRef = useRef();
    const [loading, setLoading] = useState(false);
    const [pageRangeValid, setPageRangeValid] = useState(true);

    useEffect(() => {
        if (Data.sectionsList.length <= 0 && Data.selectedFile?.id) {
            if ('epub' === Data.selectedFile?.extension) {
                setLoading(true);
                Data.setSectionsList([]);
                POST({
                    url: 'chat-from-files/file-subsections',
                    content: {
                        fileId: Data.selectedFile.id
                    },
                })
                    .then(response => {
                        if (response.status) {
                            Data.setSectionsList(response.content.sections);
                        }
                    })
                    .catch(err => {
                        GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: err.message ?? 'Unable to fetch subsections!'});
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }

            if ('pdf' === Data.selectedFile?.extension) {
                Data.setSectionsList(['']);
            }
        }
    }, []);

    const pageRangeChangeHandler = event => pageRangeUpdate(event.target.value);

    const pageRangeUpdate = (input, submit = false) => {
        Data.setSectionsList([input]);

        if (!input) {
            Data.setSelectedFile(file => ({...file, subsection: null}));
            setPageRangeValid(true);
        } else {
            const match = input.match(/^(\d+|\d+-\d+)(?:,(\d+|\d+-\d+))*$/g);

            if (!match || match.length <= 0) {
                Data.setSelectedFile(file => ({...file, subsection: null}));
                setPageRangeValid(false);
                return;
            }

            Data.setSelectedFile(file => ({...file, subsection: input}));
            setPageRangeValid(true);
        }

        submit && setTimeout(Data.confirm, 500);
    };


    if ('epub' === Data.selectedFile?.extension) {
        return (
            <Container className={'files-list'}>
                {loading
                    ? <Row><Spinner/></Row>
                    : (
                        <>
                            <Row>
                                <div className="mb-3">
                                    <Form.Check type={'checkbox'}>
                                        <Form.Check.Input
                                            type={'checkbox'}
                                            onChange={event => {
                                                const newData = {};
                                                Data.sectionsList.forEach(key => {
                                                    newData[key] = event.target.checked;
                                                });
                                                Data.setSelectedFile(file => ({...file, subsection: newData}))
                                            }}
                                            checked={Object.keys(Data.selectedFile?.subsection ?? {}).filter(k => Data.selectedFile?.subsection?.[k]).length === Data.sectionsList.length}
                                        />
                                        <Form.Check.Label>Select all</Form.Check.Label>
                                    </Form.Check>
                                </div>
                            </Row>
                            {Data.sectionsList.map((section, index) => (
                                <Row key={index}>
                                    <div key={index} className="mb-3">
                                        <Form.Check type={'checkbox'}>
                                            <Form.Check.Input
                                                type={'checkbox'}
                                                onChange={event => {
                                                    Data.setSelectedFile(file => ({...file,
                                                        subsection: {
                                                            ...(file?.subsection ?? {}),
                                                            [section]: event.target.checked
                                                        }
                                                    }));
                                                }}
                                                isValid={Data.selectedFile?.subsection?.[section]}
                                                checked={!!Data.selectedFile?.subsection?.[section]}
                                            />
                                            <Form.Check.Label>{section}</Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </Row>
                            ))}
                        </>
                    )
                }
            </Container>
        );
    }

    if ('pdf' === Data.selectedFile?.extension) {
        return (
            <Container className={'files-list'}>
                <Form onSubmit={event => {
                    event.preventDefault();
                    pageRangeUpdate(pdfInputRef.current.value, true);
                }}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Choose Specific Page Range (Optional)</Form.Label>
                        <Form.Control
                            ref={pdfInputRef}
                            size={'lg'}
                            type={'text'}
                            placeholder={'e.g. 72-88,90'}
                            value={Data.sectionsList?.[0] ?? ''}
                            onChange={pageRangeChangeHandler}
                        />
                        {!pageRangeValid && <Form.Text className={'text-danger'}>Invalid range</Form.Text>}
                    </Form.Group>
                </Form>
            </Container>
        )
    }
};