import {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import GlobalEvents from "../../../events/GlobalEvents";

export default () => {
    const [open, setOpen] = useState(false);

    const [details, setDetails] = useState({});

    useEffect(() => {
        GlobalEvents.interpret('SHOW_SOURCE_DOCS', payload => {
            setDetails(payload);
            setOpen(true);
        })
    }, []);

    useEffect(() => {
        !open && setDetails({});
    }, [open]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col bg-white shadow-xl">
                                        <div className="bg-cyan-700 px-4 py-6 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-black font-semibold leading-6 text-white">
                                                    Source Documents
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md text-indigo-200 hover:text-white focus:outline-none"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-gray-100">
                                                    {details.content}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="px-4 py-6 bg-white overflow-y-auto">
                                            <div className="max-w-3xl mx-auto">
                                                <div className="space-y-4">
                                                    {details?.sourceDocuments?.map((doc, i) => (
                                                        <details key={i} className="group [&_summary::-webkit-details-marker]:hidden">
                                                            <summary className="flex items-center justify-between p-4 rounded-lg bg-gray-50 cursor-pointer">
                                                                <h3 className="text-sm font-medium text-gray-900">{doc.metadata.subsection}</h3>
                                                                <svg
                                                                    className="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180"
                                                                    xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                    viewBox="0 0 24 24" strokeWidth="1.5"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                                                </svg>
                                                            </summary>
                                                            <span className="px-4 pt-4 text-sm text-gray-900">
                                                                <pre>
                                                                    {JSON.stringify(doc.metadata, undefined, 2)}
                                                                </pre>
                                                                <br/>
                                                                <p className="h-50">
                                                                    {doc.content}
                                                                </p>
                                                            </span>
                                                        </details>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
