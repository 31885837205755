import {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowTopRightOnSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'
import GlobalEvents from "../../../../../events/GlobalEvents";

export default () => {
    const [open, setOpen] = useState(false);
    const [book, setBook] = useState(null);
    const [expandedTaskId, setExpandedTaskId] = useState(null);
    const [tasks, setTasks] = useState({
        total: 0,
        completed: 0,
        success: 0,
        alreadyProcessed: 0,
        notAnImage: 0,
        error: 0,
        remaining: -1,
        percentage: 0,
    });
    const [taskAllDetails, setTaskAllDetails] = useState({});

    useEffect(() => {
        GlobalEvents.interpret('SHOW_ALT_TEXT_GENERATION_TASK_DETAILS', ({bookId, tasks, taskAllDetails}) => {
            setBook(bookId);
            setTasks(tasks);
            setTaskAllDetails(taskAllDetails);
            setOpen(true);
        });
    }, []);

    useEffect(() => {
        if (!open) {
            setBook(null);
            setExpandedTaskId(null);
            setTaskAllDetails({});
            setTasks({
                total: 0,
                completed: 0,
                success: 0,
                alreadyProcessed: 0,
                notAnImage: 0,
                error: 0,
                remaining: -1,
                percentage: 0,
            });
        }
    }, [open]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'success':
                return 'bg-green-100 text-green-800';
            case 'error':
            case 'prompt_not_found':
            case 'not_updated':
            case 'openai_assistant_failure':
            case 'error_while_processing':
                return 'bg-red-100 text-red-800';
            case 'already_exists':
                return 'bg-yellow-100 text-yellow-800';
            case 'not_an_image':
                return 'bg-gray-100 text-gray-800';
            default:
                return 'bg-blue-100 text-blue-800';
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen md:max-w-[50vw] shadow-2xl">
                                    <div className="bg-cyan-700 px-4 py-6 sm:px-6">
                                        <div className="flex items-center justify-between">
                                            <Dialog.Title className="text-black font-semibold leading-6 text-white">
                                                Task Status
                                            </Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="relative rounded-md text-indigo-200 hover:text-white focus:outline-none"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close</span>
                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-2xl">
                                        <div className="flex-1">
                                            <div className="bg-gray-50 px-4 py-6 sm:px-6">
                                                <div className="flex items-start justify-between space-x-3">
                                                    <div className="space-y-1">
                                                        {book && (
                                                            <Dialog.Title className="flex text-base font-semibold leading-6 text-gray-900">
                                                                Book: {book} &nbsp;&nbsp;
                                                                <ArrowTopRightOnSquareIcon
                                                                    className="h-4 w-4 cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            `${process.env.REACT_APP_PRESSBOOKS_URL}/${book}`,
                                                                            'popUpWindow',
                                                                            'height=700,width=1000,left=50,top=50,resizable=yes,scrollbars'
                                                                        );
                                                                    }}
                                                                />
                                                            </Dialog.Title>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                                                <div className="mx-auto px-4">
                                                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                                                        <div className="p-4 bg-gray-50 border-b">
                                                            <h2 className="text-xl font-semibold text-gray-800">Task Status</h2>
                                                            <div className="mt-2 flex justify-between items-center">
                                                                <p className="text-sm text-gray-600">{tasks.completed}/{tasks.total} tasks completed</p>
                                                                <p className="text-sm font-medium text-blue-600">{tasks.percentage.toFixed(1)}%</p>
                                                            </div>
                                                            <div className="mt-2 w-full bg-gray-200 rounded-full h-2.5">
                                                                <div className="bg-blue-600 h-2.5 rounded-full" style={{width: `${tasks.percentage}%`}}></div>
                                                            </div>
                                                        </div>
                                                        <div className="p-4">
                                                            <div className="grid grid-cols-4 gap-4 mb-4">
                                                                <div className="bg-green-300 p-3 rounded-lg">
                                                                    <p className="text-sm font-medium text-green-800">Successful</p>
                                                                    <p className="text-2xl font-bold text-green-800">{tasks.success}</p>
                                                                </div>
                                                                <div className="bg-yellow-300 p-3 rounded-lg">
                                                                    <p className="text-sm font-medium text-yellow-800">Already Processed</p>
                                                                    <p className="text-2xl font-bold text-yellow-800">{tasks.alreadyProcessed}</p>
                                                                </div>
                                                                <div className="bg-gray-300 p-3 rounded-lg">
                                                                    <p className="text-sm font-medium text-gray-800">Not an Image</p>
                                                                    <p className="text-2xl font-bold text-gray-800">{tasks.notAnImage}</p>
                                                                </div>
                                                                <div className="bg-red-300 p-3 rounded-lg">
                                                                    <p className="text-sm font-medium text-red-800">Error</p>
                                                                    <p className="text-2xl font-bold text-red-800">{tasks.error}</p>
                                                                </div>
                                                            </div>
                                                            <table className="h-auto overflow-y-auto min-w-full">
                                                                <thead>
                                                                    <tr className="bg-gray-50">
                                                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">URL</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="bg-white divide-y divide-gray-200">
                                                                {Object.keys(taskAllDetails).map(key => (
                                                                    <Fragment key={key}>
                                                                        {taskAllDetails[key].result ? (
                                                                            <>
                                                                            <tr className="hover:bg-gray-50 cursor-default">
                                                                                <td
                                                                                    className="whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                                                                                    onClick={() => setExpandedTaskId(expandedTaskId === key ? null : key)}
                                                                                >
                                                                                    {taskAllDetails[key].result?.media_id ?? 'N/A'}
                                                                                </td>
                                                                                <td className="whitespace-nowrap">
                                                                                    <span
                                                                                        className={`inline-flex text-xs font-semibold rounded-full ${getStatusColor(taskAllDetails[key].result?.response)}`}
                                                                                    >
                                                                                        {taskAllDetails[key].result?.response}
                                                                                    </span>
                                                                                </td>
                                                                                <td className="whitespace-nowrap text-sm text-cyan-700 cursor-pointer">
                                                                                    {!taskAllDetails[key].result?.media_url ? 'N/A' : (
                                                                                        <span
                                                                                            onClick={() => {
                                                                                                window.open(
                                                                                                    taskAllDetails[key].result?.media_url,
                                                                                                    'popUpWindow',
                                                                                                    'height=500,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes'
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <ArrowTopRightOnSquareIcon className="h-4 w-4"/>
                                                                                        </span>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            {expandedTaskId === key && (
                                                                                <tr>
                                                                                    <td colSpan="4" className="bg-gray-50">
                                                                                        <div className="text-sm text-gray-700 gap-1 px-2 py-3">
                                                                                            {taskAllDetails[key].result?.media_id && (
                                                                                                <p className="flex text-sm px-2 gap-2">
                                                                                                    <strong>Media ID: {taskAllDetails[key].result?.media_id}</strong>
                                                                                                    <ArrowTopRightOnSquareIcon
                                                                                                        className="h-4 w-4 cursor-pointer text-cyan-700"
                                                                                                        onClick={() => {
                                                                                                            window.open(
                                                                                                                `${process.env.REACT_APP_PRESSBOOKS_URL}/${book}/wp-admin/upload.php?item=${taskAllDetails[key].result?.media_id}`,
                                                                                                                'popUpWindow',
                                                                                                                'height=700,width=1000,left=50,top=50,resizable=yes,scrollbars'
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </p>
                                                                                            )}
                                                                                            {taskAllDetails[key].result?.media_type && (
                                                                                                <p className="text-sm px-2 py-2">
                                                                                                    <strong>Media Type: {taskAllDetails[key].result?.media_type}</strong>
                                                                                                </p>
                                                                                            )}

                                                                                            {taskAllDetails[key].error &&
                                                                                                <p className="text-red-600"><strong>Error:</strong> {taskAllDetails[key].error}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                            </>
                                                                        ) : (
                                                                            <tr className="px-4 py-2 text-cyan-400 hover:bg-gray-50 cursor-default">Awaiting result...</tr>
                                                                        )}
                                                                    </Fragment>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
