import {Card, Col, OverlayTrigger, Row, Spinner, Tooltip, Accordion} from "react-bootstrap";
import {useContext, useEffect, useMemo, useState} from "react";
import {DataContext} from "../../../../contexts/DataProviderContext";
import {Clipboard, ClipboardCheckFill, ClipboardData, ClipboardDataFill, Trash, PersonFill} from 'react-bootstrap-icons';
import Markdown from "react-markdown";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {coldarkDark} from 'react-syntax-highlighter/dist/esm/styles/prism';
import ClipboardJS from 'clipboard';
import remarkGfm from 'remark-gfm'

const MessageCard = prompt => {
    const Data = useContext(DataContext);
    const [hover, setHover] = useState(false);
    const [copied, setCopied] = useState({});

    useEffect(() => {
        const clipboard = new ClipboardJS('.copy-card');

        clipboard.on('success', e => {
            if (e.trigger.dataset.clipboardId) {
                setCopied({[e.trigger.dataset.clipboardId]: true});
            }
            setTimeout(() => {
                setCopied({[e.trigger.dataset.clipboardId]: false});
            }, 3000);
            e.clearSelection();
        });

        return () => {
            clipboard.destroy();
        }
    }, []);

    if (prompt.user === 'system') {
        if (prompt.details.metadata?.context) {
            return (<Accordion.Item eventKey={prompt.index}>
                <Card className={'d-flex flex-row card-' + prompt.user}>
                    <Accordion.Header>
                    {prompt.user && <Card.Header className={'p-3'}>
                        <Header {...prompt} />
                    </Card.Header>}
                    </Accordion.Header>
                    <Accordion.Body>
                        <Card.Body className={'p-3 message-card'}>
                            {prompt.content && ('string' === typeof prompt.content)
                                ? <div className={'content-'+prompt.index}><FormattedView {...prompt} /></div>
                                : (prompt.loading ? <Row><Col><Spinner/></Col></Row> : prompt.content)
                            }
                        </Card.Body>
                    </Accordion.Body>
                </Card>
            </Accordion.Item>);
        }

        return null;
    }

    return (
        <Card className={'mb-3 d-flex flex-row card-' + prompt.user} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {prompt.user && <Card.Header>
                <Header {...prompt} />
            </Card.Header>}
            <Card.Body className={'p-3 message-card'}>
                {prompt.content && ('string' === typeof prompt.content)
                    ? <div className={'content-'+prompt.index}><FormattedView {...prompt} /></div>
                    : (prompt.loading ? <Row><Col><Spinner/></Col></Row> : prompt.content)
                }
            </Card.Body>
            {hover && <Card.Footer>
                {('user' === prompt.user) && <OverlayTrigger placement={'top'} overlay={<Tooltip id={'trash-icon'+prompt.index}>Delete this prompt and response from the conversation</Tooltip>}><Trash className={'trash-card'} onClick={() => Data.removePrompt(prompt.index)} /></OverlayTrigger>}
                <OverlayTrigger
                    placement={'top'}
                    overlay={<Tooltip id={'simple-copy-icon'+prompt.index}>{copied?.['simple-copy-card-'+prompt.index] ? 'Copied' : 'Copy to clipboard'}</Tooltip>}
                >
                    {copied?.['simple-copy-card-'+prompt.index]
                        ? <ClipboardCheckFill className={'trash-card'}/>
                        : <Clipboard
                            className={'copy-card trash-card'}
                            data-clipboard-id={'simple-copy-card-'+prompt.index}
                            data-clipboard-text={prompt.content}
                        />
                    }
                </OverlayTrigger>
                {(['user', 'assistant'].indexOf(prompt.user) >= 0)
                    ? <OverlayTrigger
                        placement={'top'}
                        overlay={<Tooltip id={'with-context-copy-icon'+prompt.index}>{copied?.['with-context-copy-card-'+prompt.index] ? 'Copied' : 'Copy prompt and response'}</Tooltip>}
                    >
                        {copied?.['with-context-copy-card-'+prompt.index]
                            ? <ClipboardDataFill className={'trash-card'}/>
                            : <ClipboardData
                                className={'copy-card trash-card'}
                                data-clipboard-id={'with-context-copy-card-'+prompt.index}
                                data-clipboard-text={(() => {
                                    let pmt = '';
                                    let res = '';

                                    if ('user' === prompt.user) {
                                        pmt = prompt.content;
                                        res = prompt.next?.original_content;
                                    } else if ('assistant' === prompt.user) {
                                        pmt = prompt.previous?.original_content;
                                        res = prompt.content;
                                    }

                                    return "[" +
                                        "model = "+Data.settings.model + "\n"+
                                        "prompt = "+pmt + "\n"+
                                        "response = "+res + "\n"+
                                        "]";
                                })()}
                            />
                        }
                    </OverlayTrigger>
                    : null
                }
            </Card.Footer>}
        </Card>
    );
};

const Header = prompt => {
    const Data = useContext(DataContext);
    const roles = {system: 'Initial Context', user: 'You', assistant: 'Cognella Assistant'};

    const head = useMemo(() => {
        if (['user', 'assistant'].indexOf(prompt.user) >= 0) {
            if (prompt.user == 'user') {
                return <PersonFill className="person-fill" title={roles[prompt.user]}/>
            } else {
                return <img src={'cognella_icon.jpg'} width={32} title={roles[prompt.user]} alt={roles[prompt.user]} />
            }
        }

        if (prompt.user === 'system') {

            if (prompt.details.metadata?.instructions) {
                return 'Instructions';
            }

            if (prompt.details.metadata?.context) {
                let header = roles.system;

                if (Data.currentConversation.contentTrimmed) {
                    header += `  (${prompt.details.metadata.contextTrimmedPercentage}%)`
                }

                return header;
            }
        }

        return null;
    }, []);

    return head;
};


export const FormattedView = prompt => {
    const Data = useContext(DataContext);
    const sender = prompt.user === 'user' ? '' : ('__' + (prompt.persona ? prompt.persona : 'Ella') + '__\n');
    // const sender = '__' + (prompt.user === 'user' ? 'You' : (prompt.persona ? prompt.persona : 'Ella')) + '__\n';
    let modified_content = sender + prompt.content;
    if (Data && Data.currentConversation && Data.currentConversation.contentTrimmed && prompt.user === 'system' && prompt.details.metadata?.context) {
        modified_content = sender + '  ```(Using just the first ' + `${prompt.details.metadata?.contextTrimmedPercentage}` + '% as the full context would exceed the token limit for this model.)```\n\n' + prompt.content;
    }
    return (
        <Markdown
            components={{
                code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                        <SyntaxHighlighter
                            {...props}
                            children={String(children).replace(/\n$/, '')}
                            style={coldarkDark}
                            language={match[1]}
                            PreTag="div"
                        />
                    ) : (
                        <code {...props} className={className}>
                            {children}
                        </code>
                    )
                },
            }}
            rehypePlugins={[remarkGfm]}
        >
            {modified_content}
        </Markdown>
    );
};

export default MessageCard;