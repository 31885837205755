import {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import GlobalEvents from "../events/GlobalEvents";

const FeedbackForm = () => {

    const ELLA_FEEDBACK_REPORTING = true;
    const [open, setOpen] = useState(false);

    const [paramString, setParamString] = useState(null);

    useEffect(() => {
        GlobalEvents.interpret('SHOW_FEEDBACK_FORM', data => {
            setParamString(null);
            const metadata = data.convMetadata ?? null;

            const user = window.user;
            const isEmail = user && (user.indexOf('@') > 0);
            const email =  isEmail ? user : 'developer@cognella.com';
            let conversationDetails = 'N/A';
            let conversationInfo = 'N/A';

            if (data.convId) {

                conversationDetails = `Conversation ID: ${data.convId}`;
                conversationInfo = `Supervisor Assistant ID: ${metadata.supervisor_assistant_id}`;

                if (metadata && metadata.thread_ids) {
                    conversationDetails += ` | Thread IDs: ${metadata.thread_ids}`
                }
                if (metadata && metadata.involved_assistant_ids) {
                    conversationInfo += ` | Involved Assistant IDs: ${metadata.involved_assistant_ids}`
                }
            }

            const tempData = {
                'Conversation Details': conversationDetails,
                'Conversation Info': conversationInfo,
                'User Email': email,
            }

            const str = Object.keys(tempData).map(key => encodeURI(`${key}=${tempData[key]}`));
            setParamString(str.join('&'))
            setOpen(true);
        });
    }, []);

    if (!ELLA_FEEDBACK_REPORTING) return null;

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md shadow-lg">
                                    <div className="flex h-full flex-col bg-white shadow-xl">
                                        <div className="bg-cyan-700 px-4 py-6 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-black font-semibold leading-6 text-white">
                                                    &nbsp;
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md text-indigo-200 hover:text-white focus:outline-none"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="h-full bg-white">
                                            <div className="h-full max-w-3xl mx-auto">
                                                <div className="space-y-4">
                                                    <iframe
                                                        className="h-[91vh] w-full clickup-embed clickup-dynamic-height"
                                                        src={process.env.REACT_APP_COGNELLA_FEEDBACK_FORM+'?'+paramString}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


export default FeedbackForm;