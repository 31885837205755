import {Fragment, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {Dialog, Popover, Transition} from '@headlessui/react'
import {AdjustmentsVerticalIcon, ChevronDownIcon} from "@heroicons/react/20/solid";
import GlobalEvents from "../../events/GlobalEvents";
import OutsideClickHandler from "react-outside-click-handler";
import {CompletionV2Context} from "../../contexts/CompletionV2Context";

export default props => {
    const V2Context = useContext(CompletionV2Context);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [inputData, setInputData] = useState('');
    const [onChangeHandler, setOnChangeHandler] = useState(null);
    const [showTextarea, setShowTextarea] = useState(false);

    useEffect(() => {
        GlobalEvents.interpret('TEXTAREA_WITH_UPDATE_MODAL_OPEN', payload => {
            setName(payload.name);
            setInputData(payload.content);
            setOnChangeHandler(() => payload.onChange);
            setOpen(true);
            setShowTextarea(!payload.hasOwnProperty('showTextarea') || payload.showTextarea);
        });
    }, []);

    // Flush existing data on Cancel
    useEffect(() => {
        if (!open) {
            setName('');
            setInputData('');
            setOnChangeHandler(null);
            setShowTextarea(false);
        }
    }, [open]);

    const submit = () => {
        onChangeHandler && onChangeHandler(inputData);
        setOpen(false);
    };

    return (
        <Popover className="relative pt-12">
            <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="fixed left-1/2 z-10 flex w-screen max-w-max -translate-x-1/2">
                    <OutsideClickHandler
                        onOutsideClick={() => setOpen(false)}
                    >
                        <div className="w-screen lg:max-w-[60vw] flex-auto rounded-3xl bg-white text-sm leading-normal shadow-lg ring-1 ring-gray-900/5 p-1">
                            <div className="relative transform z-10 w-screen items-end overflow-hidden pb-0 rounded-lg bg-white px-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <AdjustmentsVerticalIcon className="h-6 w-6 text-cyan-900 bg-cyan-100" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                                            <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                                                {name}
                                            </label>
                                        </h3>
                                        {V2Context.conversationId && (name === 'Context') && (
                                            <span>
                                                {V2Context.currentFile?.files?.map((file, index) => (
                                                    <p className="text-gray-700 text-left">
                                                        {file.id ? `Box File: ` : null}
                                                        <a
                                                            href={"https://app.box.com/file/" + file.id}
                                                            target="_blank"
                                                            title="Open file in a new window via Box"
                                                        >
                                                            {file.name}
                                                        </a>
                                                        {V2Context.vectorMode ? <br/> + 'Using vectorized knowledge' : null}
                                                        <br/>
                                                        <ul>
                                                            {('pdf' === file.extension) && (
                                                                <>
                                                                    <li>
                                                                        <span className="font-semibold">Requested Pages:</span>
                                                                        &nbsp;
                                                                        {file.actualSubsection ? `${file.actualSubsection} (${file.metadata?.actualSubsectionCount} pages)` : 'Full file'}
                                                                    </li>
                                                                    {file.metadata?.croppedAtPage && (
                                                                        <li>
                                                                            <span className="font-semibold">Actual Pages:</span>
                                                                            &nbsp;
                                                                            {file.metadata?.usedSubsection} ({file.metadata?.usedSubsectionCount} pages)
                                                                        </li>
                                                                    )}
                                                                </>
                                                            )}
                                                            {('epub' === file.extension) && (
                                                                <>
                                                                    <li>
                                                                        <span className="font-semibold">Requested Sections:</span>
                                                                        &nbsp;
                                                                        {file.subsection
                                                                            ? (
                                                                                <span>{file.subsection.length > 0 && `[ ${file.subsection?.join(', ')} ]`} ({file.subsection.length} sections)</span>
                                                                            )
                                                                            : 'All Sections'
                                                                        }
                                                                    </li>
                                                                    {/*<li>*/}
                                                                    {/*    <span className="font-semibold">Used Sections:</span>*/}
                                                                    {/*    &nbsp;*/}
                                                                    {/*    {file?.subsection*/}
                                                                    {/*        ? (*/}
                                                                    {/*            <span>[ {file?.metadata?.usedSubsection?.join(', ')} ] ({file.metadata?.usedSubsectionCount} sections)</span>*/}
                                                                    {/*        )*/}
                                                                    {/*        : 'All Sections'*/}
                                                                    {/*    }*/}
                                                                    {/*</li>*/}
                                                                </>
                                                            )}
                                                        </ul>
                                                    </p>
                                                ))}
                                            </span>
                                        )}
                                        {(!V2Context.vectorMode || (name !== 'Context')) && (
                                            <>
                                                {showTextarea
                                                    ? (
                                                        <div className="mt-2 shadow-sm rounded-2">
                                                            <textarea
                                                                rows={12}
                                                                className="block row- w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-900 sm:text-sm sm:leading-6"
                                                                value={inputData}
                                                                onChange={e => setInputData(e.target.value)}
                                                            />
                                                        </div>
                                                    ) : (
                                                         <div className="mt-2 shadow-sm rounded-2">
                                                            <textarea
                                                                rows={12}
                                                                readOnly={true}
                                                                className="block row- w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-900 sm:text-sm sm:leading-6"
                                                                value={V2Context.currentFile?.files?.map((file, index) => (
                                                                    file.id && file.metadata?.actualUsedContext ? (`------ File Name: ` + file.name + ` -------\n\n` + file.metadata?.actualUsedContext) : null
                                                                ))}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )}
                                    </div>
                                </div>
                                {showTextarea && (
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="w-full justify-center rounded-md bg-cyan-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-900 sm:col-start-2"
                                            onClick={() => submit()}
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </OutsideClickHandler>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};