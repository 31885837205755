import {useContext, useEffect, useState} from "react";
import {Modal, Button, Form, FloatingLabel} from "react-bootstrap";
import {DataContext} from "../../../contexts/DataProviderContext";
import GlobalEvents from "../../../events/GlobalEvents";

export default () => {
    const Data = useContext(DataContext);
    const [show, setShow] = useState(false);

    const defaultState = () => {
        return {
            instructions: Object.values(Data.settings.availableModels).find(m => {return m.models.find(v => {return v.name === Data.settings.model})}).models.find(x => {return x.name === Data.settings.model}).instructions,
            model: Data.settings.model,
        }
    };

    const [input, setInput] = useState(defaultState());
    const [modelDetails, setModelDetails] = useState(null);

    useEffect(() => {
        GlobalEvents.interpret('SETTINGS_SHOW', () => {
            setShow(true);
        });
    }, []);

    useEffect(() => {
        if (!show) {
            setInput(defaultState());
        }
    }, [show]);

    useEffect(() => {
        Object.values(Data.settings.availableModels).forEach(p => {
            p.models.forEach(m => {
                if (m.name === input.model) {
                    setModelDetails({
                        ...m,
                        provider: p.provider
                    });
                    setInput(i => ({...i, instructions: m.instructions}));
                }
            });
        });
    }, [input.model]);

    const confirm = event => {
        event.preventDefault();
        Data.confirmSettings(input);
        setShow(false);
    };

    if (!show) return null;

    return (
        <Modal
            show={true}
            fullscreen={'md-down'}
            size={'xl'}
            onHide={() => setShow(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={confirm}>
                    <FloatingLabel className="mb-1" label="Model">
                        <Form.Select
                            value={input.model}
                            aria-label="Floating label select example"
                            disabled={!!Data.currentConversation.document}
                            onChange={e => setInput(i => ({...i, model: e.target.value}))}
                        >
                            {Object.keys(Data.settings.availableModels).map((pKey, i) => {
                                const provider = Data.settings.availableModels[pKey];

                                return (
                                    <optgroup key={i} label={provider.provider}>
                                        {provider.models.map((model, j) => (
                                            <option key={`${i}-${j}`} disabled={!model.isAvailable} value={model.name}>{model.label}</option>
                                        ))}
                                    </optgroup>
                                );
                            })}
                        </Form.Select>
                    </FloatingLabel>

                    {modelDetails
                        ? (
                            <div className="mb-3">
                                <Form.Text muted>Provider: {modelDetails?.provider}</Form.Text><br/>
                                <Form.Text muted>Model: {modelDetails?.name}</Form.Text><br/>
                                <Form.Text muted>Capacity: {modelDetails?.context}</Form.Text><br/>
                                <Form.Text muted>Pricing: {modelDetails?.pricing}</Form.Text><br/>
                                <Form.Text muted>Description: {modelDetails?.description}</Form.Text><br/>
                            </div>
                        )
                        : null
                    }

                    <FloatingLabel className="mb-3" label="Instructions">
                        <Form.Control
                            as="textarea"
                            value={input.instructions}
                            placeholder="Type in your instructions"
                            disabled={!!Data.currentConversation.document}
                            style={{height: '300px'}}
                            onChange={e => setInput(i => ({...i, instructions: e.target.value}))}
                        />
                    </FloatingLabel>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!!Data.currentConversation.document} variant={'success'} onClick={confirm}>Update</Button>
                <Button variant={'danger'} onClick={() => setShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};