import {useState, useEffect, useMemo} from "react";
import GlobalEvents from "../events/GlobalEvents";
import LoadingDots from "../pages/completionV2/components/LoadingDots";
import {useNavigate} from "react-router-dom";

export default () => {
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [items, setItems] = useState({});

    const showReturn = useMemo(() => {
        let showR = true;
        if (Object.keys(items).length > 0) {
            for (const key in items) {
                if (!items[key].showReturn) {
                    showR = false;
                    break
                }
            }
        } else {
            showR = false;
        }
        return showR;
    }, [items]);

    useEffect(() => setShow(Object.keys(items).length > 0), [items]);

    useEffect(() => {
        GlobalEvents.interpret('SHOW_LOADING_CANVAS', data => {
            const loaderType = data.type ?? 'default';
            const show = data.show ?? true;
            const message = data.message ?? 'Loading';
            const description = data.description ?? 'Please wait while we cook the contents for you.';
            const showReturn = data.showReturn ?? false;

            if (show) {
                setItems(aItems => {
                    aItems = JSON.parse(JSON.stringify(aItems), true);
                    aItems[loaderType] = {
                        message,
                        description,
                        showReturn
                    }
                    return aItems;
                });
            } else {
                setItems(aItems => {
                    aItems = JSON.parse(JSON.stringify(aItems), true);
                    if (aItems.hasOwnProperty(loaderType)) {
                        delete aItems[loaderType];
                    }
                    return aItems;
                });
            }
        });
    }, []);

    const returnHome = () => {
        setItems({});
        navigate('/');
    }

    if (!show) return null;

    return (
        <div className="fixed flex flex-wrap h-screen bg-white w-screen z-[10000000000000]">
            <div className="flex-col h-full w-full gap-y-1">
                <div className="flex h-full">
                    <div className="text-center align-middle h-auto w-auto justify-center m-auto">
                        <h3 className="mt-2 text-sm font-semibold text-gray-900"><LoadingDots/></h3>
                        {Object.keys(items).map((itemKey, key) => (
                            <div key={key}>
                                <h3 className="mt-2 text-sm font-semibold text-gray-900">{items[itemKey].message}</h3>
                                <p className="mt-1 text-sm text-gray-500">{items[itemKey].description}</p>
                            </div>
                        ))}
                        {/*{showReturn && (*/}
                        {/*    <a*/}
                        {/*        className="mt-2 text-sm text-sm text-danger fw-bold"*/}
                        {/*        href="javascript:void(0)"*/}
                        {/*        onClick={returnHome}*/}
                        {/*    >*/}
                        {/*        Return to Home*/}
                        {/*    </a>*/}
                        {/*)}*/}
                    </div>
                </div>
            </div>
        </div>
    );
}
