import {useContext} from "react";
import {DataContext} from "../../../contexts/DataProviderContext";
import GenericModalWithTextareaField from "../GenericModalWithTextareaField";
import GlobalEvents from "../../../events/GlobalEvents";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import {PaperClipIcon, QuestionMarkCircleIcon} from "@heroicons/react/24/outline";
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";

export default () => {
    const Data = useContext(DataContext);
    const V2Context = useContext(CompletionV2Context);

    return (
        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 w-full">
            <div className="flex items-center gap-x-4 lg:gap-x-6 w-full">
                <span className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 w-full">
                    <div className="flex justify-between items-center w-full">
                        <div className="flex justify-between items-center space-x-4 w-full">
                            <div className="flex flex-col mx-auto sm:ml-0 md:w-fit lg:w-fit">                                
                                <div className="flex flex-row justify-center space-x-4 w-full text-slate-600 font-sans font-medium sm:font-small md:text-center lg:text-center text-base">
                                    {Data?.currentConversation?.document?.files?.map((file, i) => (
                                        <>
                                        {file.name}
                                        {(['pdf', 'epub'].indexOf(V2Context.currentFile?.files[i]?.extension) >= 0) && V2Context.currentFile?.files[i]?.metadata?.usedSubsectionCount
                                            ? (
                                                <span>
                                                    &nbsp;
                                                    {('pdf' === V2Context.currentFile?.files[i]?.extension) && (
                                                        <>({V2Context.currentFile?.files[i]?.metadata?.usedSubsectionCount} pages)</>
                                                    )}
                                                    {('epub' === V2Context.currentFile?.files[i]?.extension) && (
                                                        <>({V2Context.currentFile?.files[i]?.metadata?.usedSubsectionCount} sections)</>
                                                    )}
                                                </span>
                                            )
                                        : null}
                                        {i < Data?.currentConversation?.document?.files?.length - 1 && (
                                            <>,&nbsp;</>
                                        )}
                                        </>
                                    ))}
                                </div>
                                <div className="flex flex-row justify-center space-x-4 w-full text-slate-400 font-sans text-[0.75rem]">
                                    {V2Context.conversationId && (
                                        <>
                                            <div className="font-medium text-[0.75rem]">
                                                <div
                                                    className="inline-flex items-center gap-x-1 sm:text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
                                                    onClick={() => GlobalEvents.dispatch('TEXTAREA_WITH_UPDATE_MODAL_OPEN', {
                                                        name: 'Context',
                                                        showTextarea: !V2Context.currentFile?.files?.[0]?.id ?? false,
                                                        content: Data.initialContext,
                                                        onChange: content => Data.confirmVectorSettings({initialContext: content}, success => {
                                                            if (success) {
                                                                Data.setInitialContext(content);
                                                            }
                                                        })
                                                    })}
                                                >
                                                    <span>Context</span>
                                                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                </div>
                                            </div>
                                            {(
                                                !Data?.currentConversation?.chatHistory[0]?.personaId
                                            ) && (
                                                <div
                                                    className="inline-flex items-center gap-x-1 sm:text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
                                                    onClick={() => GlobalEvents.dispatch('TEXTAREA_WITH_UPDATE_MODAL_OPEN', {
                                                        name: 'Instructions',
                                                        content: Data.instructions,
                                                        onChange: content => Data.confirmVectorSettings({instructions: content}, success => {
                                                            if (success) {
                                                                Data.setInstructions(content);
                                                            }
                                                        })
                                                    })}
                                                >
                                                    <span>Instructions</span>
                                                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                {false && (<PaperClipIcon
                    className="float-left h-6 cursor-pointer"
                    onClick={() => {
                        GlobalEvents.dispatch('SHOW_FILES')
                    }}
                    title={'Files'}
                />)}
                <QuestionMarkCircleIcon
                    className="float-left h-6 cursor-pointer"
                    onClick={() => {
                        GlobalEvents.dispatch('SHOW_FEEDBACK_FORM', {
                            convId: V2Context.conversationId ?? null,
                            convMetadata: V2Context.conversationMetadata ?? null
                        });
                    }}
                    title={'Give feedback'}
                />
            </div>
            <GenericModalWithTextareaField/>
        </div>
    );
}