const NoAuthPage = ({error}) => {
    return (
        <div style={{margin: 50 + 'px'}}>
            <h1>Sorry!</h1>
            {error && <div>{error}</div>}
            <div>Please sign into <a target="_top" href="https://connect.cognella.com">Cognella Connect</a> for access.</div>
        </div>
    )
}

export default NoAuthPage;
