import React from "react";

const LoadingDots = (props) => {
  return (
      <div className="loading-dots">
        <div></div>
        <div></div>
        <div></div>
      </div>
  );
};

export default LoadingDots;