import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import {useContext, useEffect, useState} from "react";
import {Portal} from "@headlessui/react";
import GlobalEvents from "../../../events/GlobalEvents";

const tabs = [
    {id: 1, name: 'Specific Context', element: <p><b>Specific Context</b></p>},
    {id: 2, name: 'Entire File Context (experimental)', element: <p><b>Entire File Context</b> (experimental)</p>}
]

export default () => {
    const V2Context = useContext(CompletionV2Context);
    const [selected, setSelected] = useState(1);

    useEffect(() => {
        if (V2Context.currentFile?.files?.length > 0 && V2Context.currentFile?.files[0]?.extension === 'epub' && selected === 2) {
            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Entire file context doesn\'t support epubs!'});
            setSelected(1);
        } else {
            V2Context.setVectorMode((2 === selected));
        }
    }, [selected]);

    return (
        <Portal>
            {V2Context.currentFile && (
            <div className={"relative p-4 mx-auto md:w-fit lg:w-fit" + (!V2Context.currentFile ? ' p-4' : '')}>
                <div className="sm:hidden">
                    <select
                        id="vectorization-chooser-tabs"
                        name="vectorization-chooser-tabs"
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        defaultValue={V2Context.vectorMode ? 2 : 1}
                        onChange={event => setSelected(Number(event.target.value))}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.id} value={tab.id}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="flex space-x-4" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <a
                                key={tab.id}
                                onClick={() => setSelected(tab.id)}
                                className={`${(selected === tab.id) ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800'} rounded-md px-3 py-2 text-sm font-medium no-underline`}
                                aria-current={(selected === tab.id) ? 'page' : undefined}
                            >
                                {tab.element}
                            </a>
                        ))}
                    </nav>
                </div>
            </div>
            )}
        </Portal>
    );
}
