import {Fragment, useState, useEffect, useContext, useMemo, useRef} from 'react'
import {Combobox, Dialog, Transition} from '@headlessui/react'
import {MagnifyingGlassIcon} from '@heroicons/react/20/solid'
import {
    DocumentPlusIcon,
    DocumentCheckIcon,
    FolderIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import GlobalEvents from "../../../events/GlobalEvents";
import ContextChangerModal from "./boxfiles";
import {GET} from "../../../services/REST";
import {CompletionV2Context} from "../../../contexts/CompletionV2Context";
import OutsideClickHandler from "react-outside-click-handler";
import {DataContext} from "../../../contexts/DataProviderContext";

const quickActions = [
    {name: 'Browse from the COGNELLA folder ...', type: 'default', icon: FolderIcon, url: '#', folder: 'cognella'},
    {name: 'Browse from the ACTIVE LEARNING folder ...', type: 'default', icon: FolderIcon, url: '#', folder: 'active'},
];

const classNames = (...classes) => classes.filter(Boolean).join(' ');

export default function ContextPicker() {

    const Data = useContext(DataContext);
    const V2Context = useContext(CompletionV2Context);

    const inputRef = useRef();

    const [query, setQuery] = useState('');
    const [options, setOptions] = useState(true);
    const [focus, setFocus] = useState(true);
    const [projects, setProjects] = useState([]);
    const personaPlaceholder = useMemo(() => {
        if (V2Context.currentPersona?.contextPlaceholder) {
            return V2Context.currentPersona?.contextPlaceholder
        }
        // if (V2Context.currentTeamPersona?.contextPlaceholder) {
        //     return V2Context.currentTeamPersona?.contextPlaceholder;
        // }
        return 'Choose context by SKU or author (limit 1 file) ...';
    }, []);
    const [defaultPlacholder, setDefaultPlaceholder] = useState(personaPlaceholder);

    const recent = useMemo(() => {
        let list = JSON.parse(localStorage.getItem('path')) || [];

        list.reverse();
        list.forEach(l => {l.icon = FolderIcon;});

        if (localStorage.getItem('file')) {
            list.unshift(JSON.parse(localStorage.getItem('file')));
            list[0].icon = DocumentPlusIcon;
        }
        if (list.length > 0) {
            list.pop();
        }

        return list;
    }, []);

    useEffect(() => {
        GET({
            url: 'chat-from-files/sku-folders'
        })
            .then(response => {
                if (response.status) {
                    if (response.content && response.content.length > 0) {
                        setProjects(response.content.map(el => ({
                            ...el,
                            icon: FolderIcon,
                        })));
                    }
                }
            })
            .catch(() => null)
            .finally(() => null);
    }, []);

    useEffect(() => {
        if (inputRef.current) {
            focus ? inputRef.current.focus() : inputRef.current.blur();
        }
    }, [focus]);

    useEffect(() => {
        GlobalEvents.interpret('ENGINE_CHANGED', () => {
            setTimeout(() => {
                setFocus(true);
                setOptions(true);
                setQuery('');
            }, 100);
        })
    }, []);

    const filteredProjects = useMemo(() => {
        return (query && query.length >= 3) ? projects.filter(project => project.name.toLowerCase().includes(query.toLowerCase())) : [];
    }, [projects, query]);

    const showBoxModal = item => {
        if (item.extension === 'epub' && V2Context.currentPersona?.id !== 'default') {
            GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: 'Epubs are not supported by personas!'});
            return;
        }
        const mode = {type: 'default'};
        if (item.id) {
            mode.type = item.hasOwnProperty('extension') ? 'file' : 'folder';
            mode.id = item.id;
            mode.name = item.name ?? '';
            mode.extension = item.extension ?? '';
            mode.subsection = item.subsection ?? null;
        }

        mode.callback = file => showBoxModal(file);

        // Skip box popup if it's a docx file
        if ('file' === mode.type) {
            if ('epub' === mode.extension && !mode.subsection) {
                setQuery('');
                GlobalEvents.dispatch('CONTEXT_SELECTOR_SHOW', mode);
                return;
            }
            // let existingFiles = (V2Context.currentPersona?.id !== 'default' && V2Context.currentFile?.files) ?  V2Context.currentFile?.files : [];
            let existingFiles = V2Context.currentFile?.files ?? [];
            if (V2Context.currentPersona?.id === 'default') {
                existingFiles = [];
            }
            
            if (!existingFiles.find(file => file.id === mode.id)) {
                existingFiles.push({
                    id: mode.id,
                    name: mode.name,
                    extension: mode.extension,
                    subsection: mode.subsection
                });
            }
            V2Context.setCurrentFile({files: existingFiles});
        
            
                setTimeout(() => {
                    setOptions(false);
                    setFocus(false);
                }, 0);
            
        } else {
            setQuery('');
            if (item.folder) {
                mode.folder_type = item.folder;
            }
            GlobalEvents.dispatch('CONTEXT_SELECTOR_SHOW', mode);
        }
    };

    const clickedInsidePopup = event => {
        const path = event.path || (event.composedPath && event.composedPath());
        if (path && path.length > 0) {
            return path.find(el => el.id && (el.id.includes('completion-v2-headless-combobox-div') || el.id.includes('completion-v2-boxfiles-modal')));
        }
        return false;
    };

    return (
        <>
        <Transition.Root
            id="completion-v2-headless-combobox"
            show={true}
            as={Fragment}
            afterLeave={() => setQuery('')}
            beforeEnter={() => setQuery('')}
            appear
        >
            <Dialog
                id="completion-v2-headless-combobox-div"
                as="div"
                className="relative z-10 m-auto"
                onClose={() => null}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
                </Transition.Child>

                <div className="w-screen z-10 p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className="mx-auto max-w-45 transform rounded-xl bg-white p-2 shadow-md ring-1 ring-black ring-opacity-10 transition-all"
                        >
                            <OutsideClickHandler
                                onOutsideClick={event => {
                                    if (clickedInsidePopup(event)) {
                                        return;
                                    }

                                    setFocus(false);
                                }}
                            >
                                <Combobox
                                    onChange={event => showBoxModal(event)}
                                    // value={V2Context.currentFile?.files ? V2Context.currentFile.files.map(file => file.name).join(', ') : (query ? query : '')}
                                    value={focus ? '' : (V2Context.currentFile?.files?.length > 0 ? V2Context.currentFile?.files[0]?.name + (V2Context.currentFile?.files?.length > 1 ? ' and ' + (V2Context.currentFile?.files?.length - 1) + ' more' : '') : (query ? query : ''))}
                                >
                                    <div className="relative">
                                        <MagnifyingGlassIcon
                                            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <Combobox.Input
                                            ref={inputRef}
                                            className="h-12 w-11/12 border-0 bg-transparent pl-11 pr-14 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm truncate"
                                            onKeyDown={() => setOptions(true)}
                                            placeholder={defaultPlacholder}
                                            onChange={event => setQuery(event.target.value)}
                                            autoComplete={'off'}
                                            onClick={() => {
                                                setOptions(true);
                                                setFocus(true);
                                            }}
                                            onFocus={() => {
                                                setOptions(true);
                                                setFocus(true);
                                            }}
                                        />
                                        {V2Context.currentFile && (
                                            <span
                                               className="flex items-center justify-center px-1 py-1 flex-shrink-0 absolute right-0 top-3 h-6 w-9 text-gray-400"
                                            >
                                                {!focus && V2Context.currentPersona?.id == 'default' && (V2Context.currentFile.files?.length == 1) && (<a
                                                    title="Open file in a new window via Box" target="_blank"
                                                    href={"https://app.box.com/file/" + V2Context.currentFile?.files[0]?.id}
                                                    className="ml-2 hover:text-black"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
                                                    </svg>
                                                </a>)}
                                                {!focus && V2Context.currentPersona?.id !== 'default' && (V2Context.currentFile.files?.length == 1) && (<span
                                                    title="Add more files"
                                                    className="ml-2 hover:text-black"
                                                    onClick={() => {setFocus(true); setOptions(true);}}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                    </svg>   
                                                </span>)}
                                                {!focus && V2Context.currentPersona?.id !== 'default' && (V2Context.currentFile.files?.length > 1) && (<span
                                                    title={V2Context.currentFile.files?.length + " files selected"}
                                                    className="ml-2 text-cyan-600"
                                                >
                                                    {V2Context.currentFile.files?.length}
                                                </span>)}
                                                {V2Context.currentFile.files?.length > 0 && (<span
                                                    className="mr-10 hover:text-black"
                                                    title="Remove all selected files"
                                                    onClick={() => {
                                                        V2Context.setCurrentFile({files: []});
                                                    }}
                                                >
                                                    <XMarkIcon className="w-5 h-5 ml-2"/>
                                                </span>)}
                                            </span>
                                        )}
                                    </div>

                                    {focus && options && (!query || filteredProjects.length > 0 || V2Context.currentFile?.files?.length > 0) && (
                                        <Combobox.Options
                                            static
                                            className="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
                                        >
                                            {true && (<li className="p-2">
                                                {!query && V2Context.currentFile?.files?.length > 0 && (
                                                    <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">Selected Files ({V2Context.currentFile?.files?.length}) </h2>
                                                )}
                                                <ul className="text-sm text-gray-700">
                                                    {(!query ? (V2Context.currentFile?.files ? V2Context.currentFile.files : []) : []).map((project) => (
                                                        <Combobox.Option
                                                            key={project.id}
                                                            value={project}
                                                            className={({active}) => classNames('flex cursor-default select-none items-center rounded-md px-3 py-2', active && 'bg-cyan-900/80 text-white')}
                                                        >
                                                            {({active}) => (
                                                                <>
                                                                    <DocumentCheckIcon
                                                                        className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')}
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span className="ml-3 flex-auto truncate">{project.name}{project.subsection? <small> {'(' + project.subsection + ')'}</small> :''}</span>

                                                                    {active && <span
                                                                        className="flex items-center justify-center px-1 py-1 flex-shrink-0 h-6 w-9 text-cyan-200 ml-9"
                                                                        >
                                                                            <a
                                                                                title="Open file in a new window via Box" target="_blank"
                                                                                href={"https://app.box.com/file/" + project.id}
                                                                                className="ml-2 hover:text-black text-cyan-200"
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
                                                                                </svg>
                                                                            </a>
                                                                            <span
                                                                                className="mr-12 hover:text-black"
                                                                                title="Remove file"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    V2Context.setCurrentFile({files: V2Context.currentFile.files.filter(file => file.id !== project.id)});
                                                                                }}
                                                                            >
                                                                                <XMarkIcon className="w-5 h-5 ml-2"/>
                                                                            </span>
                                                                        </span>
                                                                    }
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))}
                                                </ul>
                                            </li>)}
                                            <li className="p-2">
                                                {!query && recent.length > 0 && (
                                                    <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">Recently viewed</h2>
                                                )}
                                                <ul className="text-sm text-gray-700">
                                                    {(!query ? recent : filteredProjects).map((project) => (
                                                        <Combobox.Option
                                                            key={project.id}
                                                            value={project}
                                                            className={({active}) => classNames('flex cursor-default select-none items-center rounded-md px-3 py-2', active && 'bg-cyan-900/80 text-white')}
                                                        >
                                                            {({active}) => (
                                                                <>
                                                                    <project.icon
                                                                        className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')}
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span className="ml-3 flex-auto truncate">{project.name}</span>
                                                                    {active && <span className="ml-3 flex-none text-cyan-200">Jump to...</span>}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))}
                                                </ul>
                                            </li>
                                            {!query && (
                                                <li className="p-2">
                                                    <h2 className="sr-only">Quick actions</h2>
                                                    <ul className="text-sm text-gray-700">
                                                        {quickActions.map((action) => (
                                                            <Combobox.Option
                                                                value={action}
                                                                className={({active}) => classNames('flex cursor-default select-none items-center rounded-md px-3 py-2', active && 'bg-cyan-900/80 text-white')}
                                                            >
                                                                {({active}) => (
                                                                    <>
                                                                        <action.icon
                                                                            className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')}
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span className="ml-3 flex-auto truncate">
                                                                            {action.name}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )}
                                        </Combobox.Options>
                                    )}
                                    {query && filteredProjects.length === 0 && (
                                        <div className="px-6 py-14 text-center sm:px-14">
                                            <FolderIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
                                            {query.length < 3 && (
                                                <p className="mt-4 text-sm text-gray-900">Please enter at least 3 characters</p>
                                            )}
                                            {query.length >= 3 && (
                                                <p className="mt-4 text-sm text-gray-900">We couldn't find any SKU folder with term &quot;{query}&quot;</p>
                                            )}
                                        </div>
                                    )}
                                </Combobox>
                            </OutsideClickHandler>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
        <ContextChangerModal/>
        </>
    );
}
