import "./index.css";
import {Fragment, useEffect, useContext} from "react";
import {Bars3Icon, QuestionMarkCircleIcon} from "@heroicons/react/24/outline";
import {CompletionV2Context} from "../../contexts/CompletionV2Context";
import ContextPalette from "./components/ContextPalette";
import PromptPalette from "./components/PromptPalette";
import PersonaPalette from "./components/PersonaPalette";
import PagePalette from "./components/PagePalette";
import {DataContext} from "../../contexts/DataProviderContext";
import VectorModeSelector from "./components/VectorModeSelector";
import ConversationListOnSideList from "./components/ConversationsListOnSideList";
import GlobalEvents from "../../events/GlobalEvents";
import {PersonasContext} from "../../contexts/PersonasContext";

export default () => {
    const V2Context = useContext(CompletionV2Context);
    const AsstConfig = useContext(PersonasContext);

    const Data = useContext(DataContext);
    const models = Object.values(Data.settings.availableModels).map(provider => {
        return provider.models.map(model => {
            return {
                id: model.name,
                name: `${provider.provider}: ${model.label}`,
                pricing: model.pricing,
                description: model.description,
                context: model.context,
                isAvailable: model.isAvailable,
            }
        })
      }).flat()

    useEffect(() => {
        require('./tailwind-base.css');

        // On initial landing, clear out existing prompts
        V2Context.setCurrentPrompt('');
        Data.currentConversation.document = null;
        V2Context.setCurrentFile({files: []});
        const currentModel = models.find(x => {return x.id === Data?.settings?.model})
        if (currentModel) {
            V2Context.setCurrentModel(currentModel);
        }
        setTimeout(() => {
            document.getElementById("root").removeAttribute("inert")
        },100)
    }, []);

    const setSidebarOpen = state => GlobalEvents.dispatch('OPEN_SIDENAV_BAR', {state: state});

    if (!Data.settings.loaded) return null;

    return (
        <>
            <div
                className="flex items-center h-full"
            >
                <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-700 gap-x-4 px-4 sm:gap-x-6 sm:px-6 lg:px-0"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                </button>
                <div className="flex flex-col w-64 text-center mx-auto mt-4" >
                    <img src={'https://cognella.com/wp-content/uploads/2014/09/color650px.png'}/>
                </div>
                <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-700 gap-x-4 px-4 sm:gap-x-6 sm:px-6 lg:px-0"
                    title={'Give feedback'}
                    onClick={() => {
                        GlobalEvents.dispatch('SHOW_FEEDBACK_FORM', {
                            convId: V2Context.conversationId ?? null,
                            convMetadata: V2Context.conversationMetadata ?? null
                        });
                    }}
                >
                    <span className="sr-only">Give feedback</span>
                    <QuestionMarkCircleIcon className="float-left h-6 w-6 cursor-pointer" />
                </button>
            </div>
            <PersonaPalette/>
            {
                (
                    (V2Context.currentPersona?.id && V2Context.currentPersona?.contextRequired) ||
                    (V2Context.currentMultiPersonas.filter(p1 => AsstConfig.personas.find(p2 => p2.id === p1).contextRequired).length > 0)
                ) &&
                <ContextPalette/>
            }
            {V2Context.currentPersona?.id?.startsWith('default') && <VectorModeSelector/>}
            {V2Context.currentPersona?.id?.startsWith('default') && <PagePalette/>}
            {(V2Context.currentPersona?.id || V2Context.currentMultiPersonas.length > 0) && <PromptPalette/>}
            <ConversationListOnSideList/>
        </>
    );
};
